import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

import WizardBox from '../components/quicksetup/WizardBox'
import Install from '../components/quicksetup/Install'
import GoLive from '../components/quicksetup//GoLive'
import Customize from '../components/quicksetup/customize/Customize'
import CallRouting from '../components/quicksetup/CallRouting'
import LeadInformation from '../components/quicksetup/LeadInformation'
import UseCaseInfo from '../components/quicksetup/UseCaseInfo'
// import quickSetupImage from '../assets/images/quick-setup-image.jpg'
import quickSetupImage from '../assets/images/quick-setup-image-latest.png'
import CreaditDetails from '../components/quicksetup/CreaditDetails'
import CallTrackingLeadInformation from '../components/quicksetup/CallTrackingLeadInfo'
import VirtualCallLeadInformation from '../components/quicksetup/VirtualCallLeadInfo'
import YourPhone from '../components/quicksetup/YourPhone'
import VirtualCreaditDetails from '../components/quicksetup/VirtualBillingInfo'
import InviteTeamMate from '../components/quicksetup/InviteTeamMate'
import WelcomeNew from '../components/quicksetup/WelcomeNew'
import Availablity from '../components/quicksetup/Availability'
import TestCall from '../components/quicksetup/TestCall'
import MakeCall from '../components/quicksetup/MakeCall'

export default function QuickSetup() {
  const [step, setStep] = useState(
    parseInt(localStorage.getItem('quick_setup')) || 1
  )
  const [flowType, setFlowType] = useState(
    localStorage.getItem('flowType') || null
  )
  const [initialPage, setInitialPage] = useState(true)
  const [values, setValues] = useState({
    step: step,
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    age: '',
    country: '',
    interests: [],
    about: ''
  })
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (localStorage.getItem('role_name')?.toLocaleLowerCase() === 'owner') {
      setInitialPage(false)
      localStorage.setItem('flowType', 'invited')
      setFlowType('invited')
    } else {
      if (localStorage.getItem('flowType')) {
        setInitialPage(false)
      } else {
        const quickSetup = localStorage.getItem('quickSetupAc')
        if (+quickSetup === 1) {
          setFlowType('quickSetupAcFlow')
          localStorage.setItem('flowType', 'quickSetupAcFlow')
          setInitialPage(false)
        } else {
          setInitialPage(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    redirectPage()
  }, [window.location.pathname])

  useEffect(() => {
    if (+step === 1 && !flowType) {
      const quickSetup = localStorage.getItem('quickSetupAc')
      if (+quickSetup === 1) {
        setInitialPage(false)
        setFlowType('quickSetupAcFlow')
        localStorage.setItem('flowType', 'quickSetupAcFlow')
      } else {
        setInitialPage(true)
      }
    } else {
      initialPage && setInitialPage(false)
    }
  }, [step])

  const handleSubmit = e => {
    e.preventDefault()
    setStep(4)
  }

  const handleChange = (event, { name, value, checked, type }) => {
    if (type === 'checkbox') {
      let { interests } = this.state
      if (checked) {
        interests = [...interests, value]
      } else {
        interests = interests.filter(item => item !== value)
      }
      setValues({
        ...values,
        interests: interests
      })
    } else {
      setValues({
        ...values,
        [name]: value
      })
    }
  }

  const next = () => {
    const count = +step + 1
    setStep(count)
    localStorage.setItem('quick_setup', count)
  }

  const prev = () => {
    setStep(+step - 1)
  }

  const redirectPage = () => {
    if (localStorage.getItem('access_token')) {
      document.title = 'Quick Setup'
      let step = parseInt(localStorage.getItem('quick_setup')),
        skipProccess = parseInt(localStorage.getItem('skip_onboarding'))
      if (flowType === 'virtualPhone') {
        if (step >= 8 || skipProccess === 1) {
          localStorage.setItem('quick_setup', 8)
          window.location.href = '/conversation'
        }
      } else {
        if (step >= 5 || skipProccess === 1) {
          localStorage.setItem('quick_setup', 8)
          window.location.href = '/conversation'
        }
      }
    } else {
      window.location.href = '/login'
    }
  }

  const handleManageStepsComponents = () => {
    let title = localStorage.getItem('flowType')
    let quickSetup = localStorage.getItem('quickSetupAc')
    if (+quickSetup === 1) {
      return stepQuickAccount()
    } else {
      switch (title) {
        case 'callback':
          return stepCallback()
        case 'callTraking':
          return stepCallTracking()
        case 'virtualPhone':
          return stepVirtualNumber()
        case 'invited':
          return stepAccount()
        default:
          return stepCallback()
      }
    }
  }

  const stepVirtualNumber = () => {
    switch (step) {
      case 1:
        return (
          <VirtualCallLeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={false}
            // btnBack={true}
          />
        )
      // return (
      //   <VirtualCreaditDetails
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //   />
      // )
      // case 2:
      //   return (
      //     <WelcomeNew
      //       values={values}
      //       handleChange={handleChange}
      //       next={next}
      //       prev={prev}
      //       btnBack={true}
      //     />
      //   )
      // return (
      //   <VirtualCallLeadInformation
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //     btnBack={true}
      //   />
      // )
      case 2:
        return (
          <YourPhone
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )
      // return (
      //   <WelcomeNew
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //     btnBack={true}
      //   />
      // )
      case 3:
        return (
          <VirtualCreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      // return (
      //   <YourPhone
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //     btnBack={true}
      //   />
      // )
      case 4:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )
      // case 6:
      //   return (
      //     <Availablity
      //       values={values}
      //       handleChange={handleChange}
      //       next={next}
      //       prev={prev}
      //       btnBack={true}
      //     />
      //   )
      // case 6:
      //   return <InviteTeamMate next={next} prev={prev} btnBack={true} />

      case 5:
      case 6:
        return <GoLive next={next} prev={prev} redirectURL="/numbers" />

      default:
        return <GoLive next={next} prev={prev} redirectURL="/numbers" />
    }
  }
  const stepCallTracking = () => {
    switch (step) {
      case 1:
        return (
          <CreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 2:
        return (
          <CallTrackingLeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 3:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 4:
        return <Install next={next} prev={prev} />
      case 5:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <CreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
    }
  }

  const stepCallback = () => {
    switch (step) {
      case 1:
        return (
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={false}
          />
        )
      case 2:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )

      case 3:
        return <Availablity next={next} prev={prev} btnBack={true} />
      // case 4:
      //   return <WelcomeNew next={next} prev={prev} btnBack={true} />
      case 4:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
    }
  }

  const stepAccount = () => {
    switch (step) {
      case 1:
        return (
          <WelcomeNew
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={false}
          />
        )
      // case 2:
      //   return (
      //     <LeadInformation
      //       values={values}
      //       handleChange={handleChange}
      //       next={next}
      //       prev={prev}
      //       btnBack={true}
      //     />
      //   )
      // case 3:
      //   return <Availablity next={next} prev={prev} />
      case 2:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 3:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <WelcomeNew handleChange={handleChange} next={next} prev={prev} />
        )
    }
  }

  const stepQuickAccount = () => {
    switch (step) {
      // case 1:
      //   return (
      //     <WelcomeNew
      //       handleChange={handleChange}
      //       next={next}
      //       prev={prev}
      //       btnBack={false}
      //     />
      //   )
      case 1:
        return (
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={false}
          />
        )
      case 2:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            isNotSkip
          />
        )
      // return (
      //   <YourPhone
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //     btnBack={true}
      //   />
      // )
      case 3:
        return (
          <YourPhone
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )
      // return <Availablity next={next} prev={prev} />
      case 4:
        return <Availablity next={next} prev={prev} />
      // return (
      //   <CallRouting
      //     values={values}
      //     handleChange={handleChange}
      //     next={next}
      //     prev={prev}
      //   />
      // )
      case 5:
        return (
          <TestCall
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )
      case 6:
        return (
          <MakeCall
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={true}
          />
        )
      case 7:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          // <WelcomeNew handleChange={handleChange} next={next} prev={prev} />
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
            btnBack={false}
          />
        )
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className="quicksetup-step">
      <div className="quicksetup-step-wrap">
        {flowType ? (
          <WizardBox step={step} />
        ) : (
          <>
            {width > 768 ? (
              <img
                src={quickSetupImage}
                className="p-3 bg-white"
                style={{ maxHeight: '570px' }}
                alt=""
              />
            ) : null}
          </>
        )}
        <div className="bg-white w-100">
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            {!flowType ? (
              <>
                <UseCaseInfo
                  setFlowType={setFlowType}
                  setInitialPage={setInitialPage}
                />
              </>
            ) : (
              handleManageStepsComponents()
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}
