import React, { Component } from 'react'
import axios from 'axios'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'

import { isAdmin } from '../../common/Title'
import moment from 'moment'

//redux
import { connect } from 'react-redux'
import { getSubscription } from '../../actions/home'

class Usage extends Component {
  state = {
    dataTable: {
      type: '3',
      header: [
        {
          headerTitle: 'Call Made To'
        },
        {
          headerTitle: 'Time'
        },
        {
          headerTitle: 'Credits Used'
        }
      ],
      tableContentData: [
        {
          columnOne: '+71-5676545443',
          columnTwo: '22-05-2019 22:45',
          columnThree: '$0.87'
        }
      ]
    },
    max_call: null,
    used_call: null,
    max_message: null,
    used_message: null,
    percent_message: null,
    percent_call: null,
    isLoading: true,
    max_users: null,
    used_users: null,
    percentageUsers: null,
    digital_calls_used: null,
    max_digital_calls: null,
    current_term_end: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscription !== prevProps.subscription) {
      const d = this.props.subscription.data
      const p_user = (d.users_used / d.max_users) * 100
      const p_call = (d.calls_used / d.max_calls) * 100
      const p_msg = (d.sms_used / d.max_sms) * 100

      const max_leads = d.max_leads
      const used_leads = d.leads_used

      const p_leds = (used_leads / max_leads) * 100

      let p_dig = 0

      if (d.digital_calls_used) {
        p_dig = (d.digital_calls_used / d.max_leads) * 100
      }

      this.setState({
        endDate: d?.current_term_end?.substring(0, 10),
        startDate: d?.current_term_start?.substring(0, 10),
        max_user: d.max_users,
        used_user: d.users_used,
        outgoing_calls: d.outgoing_calls,
        max_leads: max_leads,
        used_leads: used_leads,
        max_call: d.max_calls,
        used_call: d.incoming_calls,
        max_dig: d.max_digital_calls,
        used_dig: d.digital_calls_used,
        max_message: d.max_sms,
        used_message: d.sms_used,
        percent_call: p_call,
        percent_dig: p_dig,
        user_percentage: p_user,
        percent_message: p_msg,
        isLoading: false,
        credits: parseFloat(d.credits_in_dollars, 2),
        p_leds: p_leds
      })
    }
  }

  componentDidMount() {
    if (!isAdmin()) return

    if (this.props.subscription) {
      const d = this.props.subscription.data
      const p_user = (d.users_used / d.max_users) * 100
      const p_call = (d.calls_used / d.max_leads) * 100
      const p_msg = (d.sms_used / d.max_sms) * 100
      const p_outgoing = (d.outgoing_calls / d.max_leads) * 100

      const max_leads = d.max_leads
      const used_leads = d.leads_used

      const p_leds = (used_leads / max_leads) * 100

      let p_dig = 0

      if (d.digital_calls_used) {
        p_dig = (d.digital_calls_used / d.max_leads) * 100
      }

      this.setState({
        endDate: d.current_term_end?.substring(0, 10),
        startDate: d.current_term_start?.substring(0, 10),
        max_user: d.max_users,
        used_user: d.users_used,
        outgoing_calls: d.outgoing_calls,
        max_leads: max_leads,
        used_leads: used_leads,
        max_call: d.max_calls,
        calls_used: d.calls_used,
        used_call: d.incoming_calls,
        max_dig: d.max_digital_calls,
        used_dig: d.digital_calls_used,
        incoming_calls: d.incoming_calls,
        max_message: d.max_sms,
        used_message: d.sms_used,
        percent_call: p_call,
        percent_dig: p_dig,
        user_percentage: p_user,
        percent_message: p_msg,
        isLoading: false,
        per_outgoing: p_outgoing,
        credits: parseFloat(d.credits_in_dollars, 2),
        p_leds: p_leds
      })
    } else {
      this.props.getSubscription()
    }
    /*  const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription`

    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false
    }

    return axios(settings)
      .then(res => {
        const d = res.data.data
        const p_user = (d.users_used / d.max_users) * 100
        const p_call = (d.calls_used / d.max_calls) * 100
        const p_msg = (d.sms_used / d.max_sms) * 100

        const max_leads = d.max_calls + d.max_sms + d.max_digital_calls
        const used_leads = d.calls_used + d.sms_used + d.digital_calls_used

        

        const p_leds = (used_leads / max_leads) * 100   
        
        let p_dig = 0

        if(d.digital_calls_used){
          p_dig =  (d.digital_calls_used / d.max_digital_calls) * 100
        }

        this.setState({
          endDate: d.current_term_end.substring(0, 10),
          max_user: d.max_users,
          used_user: d.users_used,
          max_leads: max_leads,
          used_leads: used_leads,
          max_call: d.max_calls,
          used_call: d.calls_used,
          max_dig: d.max_digital_calls,
          used_dig: d.digital_calls_used,
          max_message: d.max_sms,
          used_message: d.sms_used,
          percent_call: p_call,
          percent_dig: p_dig,
          user_percentage:p_user,
          percent_message: p_msg,
          isLoading: false,
          credits: d.credits_in_dollars,
          p_leds:p_leds
        })      
      })
      .catch(err => {
       
      }) */
  }

  render() {
    console.log('this.state.data', this.state)
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="usage-wrapper">
          <div className="usage-details-wrapper">
            <h4 className="usage-heading">Usage Detail</h4>
            <div className="usage-details">
              <div>
                <p>Leads</p>
              </div>
              <label>
                {' '}
                {this.state.used_leads || 0}{' '}
                {this.state.max_leads ? ` / ${this.state.max_leads || 0}` : ``}
              </label>
            </div>

            <div className="usage-details">
              <div>
                <p>Outgoing Calls</p>
              </div>
              {/* {this.state.outgoing_calls ?    <div className="progressbar">
                  <div
                    className="progressbar-inner"
                    style={{ width: `${this.state.per_outgoing}%` }}
                  ></div>
                </div> : null } */}
              <span>{this.state.outgoing_calls}</span>
            </div>
            <div className="usage-details">
              <div>
                <p>Digital Calls</p>
              </div>
              <label>
                {this.state.used_dig || 0}{' '}
                {this.state.max_dig ? ` / ${this.state.max_dig || 0}` : ``}
              </label>
            </div>
            <div className="usage-details">
              <div>
                <p>Incoming Calls</p>
              </div>
              {/* <div className="progressbar">
                  <div
                    className="progressbar-inner"
                    style={{ width: `${this.state.user_percentage}%` }}
                  ></div>
                </div> */}
              <label>{this.state.incoming_calls || 0} </label>

              {/* <span>Used: </span> */}
            </div>
            <div className="usage-details">
        

            <div className="usage-details">
              <div>
                <p>Callbacks</p>
              </div>
              <label>
                {`${this.state.data?.used_callbacks || 0} ${
                  this.state.data?.max_callbacks
                    ? ` / ${this.state.data?.max_callbacks || 0}`
                    : ``
                }`}{' '}
              </label>
            </div>

            <div>
                <p>SMS</p>
              </div>
              <label>
                {this.state.used_message || 0}{' '}
                {this.state.max_message
                  ? ` / ${this.state.max_message || 0}`
                  : ``}{' '}
              </label>
            </div>
            <h4 className="usage-heading">Plan Detail</h4>

            <div className="usage-details">
              <div>
                <p>Plan Status</p>
              </div>
              <label>{this.props.subscription?.data?.brief_plan_status} </label>
            </div>
            <div className="usage-details">
              <div>
                <p>Plan Type</p>
              </div>
              <label>{this.props.subscription?.data?.plan_type} </label>
            </div>
            <div className="usage-details">
              <div>
                <p>Virtual Numbers</p>
              </div>
              <label>{this.props.subscription?.data?.phone_numbers} </label>
            </div>
            <div className="usage-details">
              <div>
                <p>Users</p>
              </div>
              {/* <div className="progressbar">
                  <div
                    className="progressbar-inner"
                    style={{ width: `${this.state.user_percentage}%` }}
                  ></div>
                </div> */}
              <label>
                {this.state.used_user || 0}{' '}
                {this.state.max_users ? ` / ${this.state.max_users || 0}` : ``}
              </label>

              {/* <span>Used: </span> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  subscription: state.home.subscription
})

export default connect(mapStateToProps, { getSubscription })(Usage)
