import React, { useEffect, useState } from 'react'
import { Col, Container, FormLabel, Row, FormGroup } from 'react-bootstrap'
import visa from '../../assets/images/visa-payment-info.svg'
import mc from '../../assets/images/mc.svg'
import ame from '../../assets/images/ame.svg'
import dis from '../../assets/images/dis.svg'
import {
  Input,
  Dropdown,
  Checkbox,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CommonNotify } from '../../common/CommonNotify'

export default function VirtualCreaditDetails(props) {
  const [planList, setPlanList] = useState([])
  const [dropdownValue, setDropdownValue] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)
  const [lable, setLable] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    cardNumber: '',
    expireDate: '',
    cvv: '',
    zipCode: '',
    autoFund: false,
    name: '',
    country: ''
  })

  useEffect(() => {
    getPlanName()
  }, [])

  const getPlanName = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/plans?type=numbers`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setPlanList(res?.data?.data || [])
        }
      })
      .catch(function (error) {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  useEffect(() => {
    if (planList && planList?.length) {
      let list = []
      const lists = planList?.map((data, index) => {
        if (+data?.is_yearly === 0) {
          list.push({
            key: index,
            text: data?.name,
            value: data?.code,
            data: data
          })
        }
      })
      setLable(list?.[0]?.text)
      setSelectedPlan(list?.[0]?.data)
      setSelectedValue(list?.[0]?.value)
      setDropdownValue(list || [])
    }
  }, [planList])
  const onCheckboxClickHandler = ({ value }) => {
    setSelectedValue(value)
    const filter = planList?.find(({ code }) => code === value)
    setLable(filter?.name)
    setSelectedPlan(filter)
  }

  const getDateValue = () => {
    let date = ''
    if (selectedPlan?.trial_period) {
      let dates = new Date()
      date = dates.setDate(dates.getDate() + +selectedPlan?.trial_period)
      return moment(date).format('MMM DD, YYYY')
    } else {
      return moment().format('MMM DD, YYYY')
    }
  }

  const handleSave = async () => {
    const { name, cvv, cardNumber, expireDate, zipCode } = formValue || {}
    if (!name) {
      CommonNotify('Card holder name is required', 'warning')
      return
    }
    if (!cardNumber) {
      CommonNotify('Card number is required', 'warning')
      return
    }
    if (cardNumber?.trim()?.length < 16 || cardNumber?.trim()?.length > 16) {
      CommonNotify('Card number is invalid', 'warning')
      return
    }
    if (!expireDate) {
      CommonNotify('Expire date is required', 'warning')
      return
    }
    if (expireDate?.trim()?.length < 6 && expireDate?.trim()?.length > 7) {
      CommonNotify(
        'Expire date is invalid, Please add like that Ex:02/2050',
        'warning'
      )
      return
    }
    if (!cvv) {
      CommonNotify('Security Code is required', 'warning')
      return
    }
    if (cvv && (cvv?.length < 3 || cvv?.length > 4)) {
      CommonNotify('Security Code is invalid', 'warning')
      return
    }
    if (zipCode && (zipCode?.length < 5 || zipCode?.length > 6)) {
      CommonNotify('Zip code is invalid', 'warning')
      return
    }
    setLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`
    const payload = {
      name: formValue?.name?.trim(),
      card_number: formValue?.cardNumber?.trim(),
      expiry: formValue?.expireDate?.trim(),
      cvv: formValue?.cvv?.trim(),
      country: formValue?.country?.trim(),
      zip: formValue?.zipCode?.trim()
    }
    await Axios.post(URL, payload)
      .then(res => {
        if (res && res?.data?.data) {
          handleSaveAutoFund()
        }
      })
      .catch(error => {
        setLoading(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  const handleSaveAutoFund = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/auto-recharge`
    const payload = {
      auto_charge_status: formValue?.autoFund === false ? 0 : 1,
      auto_charge_amount: 10,
      auto_charge_when: 10
    }
    await Axios.put(url, payload)
      .then(res => {
        if (res && res?.data?.data) {
          setLoading(false)
          if (props?.next) {
            return props?.next()
          }
        }
      })
      .catch(error => {
        setLoading(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }


  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 6);

    return expDateFormatter;
  };
  const onChangeExp = (e) => {
    setFormValue({
      ...formValue,
      expireDate: e.target.value
    });
  };

  return (
    <Container>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Row>
        <Col md={8}>
          <div className="virtual-cc">
            <div className="payment-info">
              <h2>Start your 14 days FREE trial</h2>
              <p>
                You will <b>Not</b> be charged at all. We ask for billing info
                to prevent people from creating multiple accounts and preventing
                abuse. These free callback credits costs us real money to
                service &#128578;
              </p>
              <div className="wcsh-row mb-4">
                <h2>Credit card info</h2>
                <div className="cc-logo">
                  <img src={visa} alt="Visa" />
                </div>
                <div className="cc-logo">
                  <img src={mc} alt="Master Card" />
                </div>
                <div className="cc-logo">
                  <img src={ame} alt="American Express" />
                </div>
                <div className="cc-logo">
                  <img src={dis} alt="Discover" />
                </div>
                <span
                  title={`We ask for your billing details to reduce the risk of fraud and to prevent interruption in service if you decide to continue with LimePhone after the trial ends. We’ll never charge during your free trial, and you can cancel anytime`}
                  className="why-text cursor-pointer"
                >
                  Why we ask?
                </span>
              </div>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Card Holder Name *</FormLabel>
                    <Input
                      fluid
                      placeholder="Card Holder"
                      value={formValue?.name}
                      onChange={e => {
                        setFormValue({
                          ...formValue,
                          name: e.target.value
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Credit Card Number *</FormLabel>
                    <Input
                      fluid
                      type="number"
                      placeholder="XXXX XXXX XXXX XXXX"
                      value={formValue?.cardNumber}
                      onChange={e => {
                        setFormValue({
                          ...formValue,
                          cardNumber:
                            e.target.value?.length <= 16
                              ? e.target.value
                              : formValue?.cardNumber
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Expiration Date *</FormLabel>
                    <input
                      type="text"
                      name="expiry-data"
                      className="cardetails-input"
                      placeholder="MM/YYYY"
                      onChange={onChangeExp}
                      value={expriy_format(formValue.expireDate)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Security Code *</FormLabel>
                    <Input
                      fluid
                      type="number"
                      value={formValue?.cvv}
                      onChange={e => {
                        setFormValue({
                          ...formValue,
                          cvv:
                            e.target.value?.length <= 3
                              ? e.target.value
                              : formValue?.cvv
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md={6}>
                  <FormGroup>
                    <FormLabel>Zip / Postal Code</FormLabel>
                    <Input
                      fluid
                      type="number"
                      placeholder="395006"
                      value={formValue?.zipCode}
                      onChange={e => {
                        setFormValue({
                          ...formValue,
                          zipCode: e.target.value
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Country</FormLabel>
                    <Input
                      fluid
                      placeholder="US"
                      value={formValue?.country}
                      onChange={e => {
                        setFormValue({
                          ...formValue,
                          country: e.target.value
                        })
                      }}
                    />
                  </FormGroup>
                </Col> */}
                <Col md={12}>
                  <FormGroup>
                    <Checkbox
                      label="Fund my account balance automatically"
                      checked={formValue?.autoFund}
                      onChange={() => {
                        setFormValue({
                          ...formValue,
                          autoFund: !formValue?.autoFund
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Button primary fluid type="button" onClick={handleSave}>
                      Start Your Free Trial
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <div className="sort-notes">
                <span>
                  <i className="fa fa-check"></i>You won’t be billed today.
                </span>
                <span>
                  <i className="fa fa-check"></i>FREE cancellation before{' '}
                  {moment()
                    .add(14, 'days')
                    .format('MMM DD, YYYY')}
                </span>
              </div>
                 <div style={{display:'flex', justifyContent:'center'}}>
                Please disable VPN before signup as your card might be rejected.</div>
            </div>
          </div>
          {/* <div className="already-acc">
            <Link to="/logout">Already have an account? Login here</Link>
          </div> */}
        </Col>
        <Col md={4}>
          <div className="virtual-cc">
            <div className="card-setting">
              <div class="card-tail">Free Trial</div>
              <div class="wcsh-row">
                <h2>Choose Your Plan</h2>
                <Dropdown
                  placeholder="Select plan"
                  onChange={(e, result) => onCheckboxClickHandler(result)}
                  fluid
                  search
                  selection
                  name="plan"
                  value={selectedValue}
                  options={dropdownValue}
                  className="mb-3"
                />
                {/* <p>
                  Your Free Trial includes ${selectedPlan?.price || 0} in Usage
                  Credit.
                </p> */}
                <div>
                  <div className="plan-detail">
                    <h6>Plan Includes</h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedPlan?.description
                      }}
                    ></div>
                    <div>Includes one user</div>
                    {/* {selectedValue === "limephone_starter" ?
                      <> <div>1 Virtual US/UK Number
                      </div>
                        <div>One User</div></> : <><div> Virtual Numbers
                        </div>
                        <div>Three Users</div> </>} */}
                  </div>
                  <div className="plan-detail">
                    <h6>
                      Today’s Total <span>$0</span>
                    </h6>
                    <h6>
                      Total After Trial <span>${selectedPlan?.price || 0}</span>
                    </h6>
                    <p>
                      To be charged only if you love LimePhone. We’ll email you
                      days before your trial ends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
