import React, { useEffect, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Title from '../../common/Title'
import Navbar from '../navbar/Navbar'
import Axios from 'axios'
import { PlusIcon } from 'evergreen-ui'
import { CommonNotify } from '../../common/CommonNotify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material'
import { scrollTo } from 'react-scroll/modules/mixins/scroller'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import {
  Dimmer,
  Dropdown,
  Icon,
  Image,
  Loader,
  Modal,
  Popup
} from 'semantic-ui-react'
import callbackIcon from '../../assets/images/callback-icon.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MissedCall from '../../assets/images/orangeCall.png'
import PickedCall from '../../assets/images/greenCall.png'
import DoneIcon from '@mui/icons-material/Done';
// import smsIcon from '../../assets/images/sms.png'
import leadsIcon from '../../assets/images/lead-icon.png'
import letterIcon from '../../assets/images/letter-l.png'
import incomingIcon from '../../assets/images/incoming-call.png'
import outboundIcon from '../../assets/images/outbound-phone.png'
// import telephone from '../../assets/images/telephone.png'
import scheduleIcon from '../../assets/images/schedule.png'
import blockedCall from '../../assets/images/no-call.png'
import scheduledCall from '../../assets/images/timer.png'
import cutCall from '../../assets/images/callRed.jpeg'
import { getLeadOwner, onChangeInterseted3 } from '../../config/leadAPI'
import smallcall from '../../assets/images/small_call.png'
import smallEmail from '../../assets/images/small_email.png'
import DummyProfileImage from '../../assets/images/dummy_profile.webp'
import callicons from '../../assets/images/call_icons.png'
import emailicon from '../../assets/images/mail_icons.png'
import CardFilters from '../../containers/CardFilters'
import RightPane from '../../components/leads/leadRightPane/rightPopUp'
import CommonButtons from '../../common/CommonButtons'
import AddNewLead from '../../containers/AddNewLead'
import CommonInput from '../../common/CommonInput'
import CommonCheckbox from '../../common/CommonCheckbox'
import RightPaneContainer from '../../common/RightPaneContainer'
import { connect } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings'
import { getFunnels } from '../../actions/home'

const ColumnTypes = {
  CARD: 'card'
}
const successIcon = [
  'completed',
  'agent_call_completed',
  'customer_call_completed',
  'completed'
]
const warningIcon = [
  'in-progress',
  'waiting_for_agent',
  'busy',
  'no-answer',
  'customer_connected',
  'scheduled',
  'cancelled',
  'rescheduled',
  'ringing',
  'agent_connected',
  'connecting_agent',
  'connecting_customer',
  'connected'
]
const checkIcon = status => {
  if (status === 'suspended') {
    return blockedCall
  }
  if (status === 'scheduled') {
    return scheduledCall
  }
  if (successIcon.includes(status)) {
    return PickedCall
  }
  if (warningIcon.includes(status)) {
    return MissedCall
  }
  return cutCall
}
const getContact = (contact, id) => {
  const invalidResponse = ['null', 'undefined', '', false]
  let result = invalidResponse.find(item => {
    if (item === contact) return item
  })
  if (result) {
    return 'Contact ' + id
  }
  return contact
}
const statusOption = [
  {
    key: 'Awaiting Review',
    text: (
      <>
        {/* <i
          style={{ color: 'orange' }}
          className="fa fa-hourglass-half mr-3"
        ></i> */}
        Pending
      </>
    ),
    value: 1
  },

  {
    key: 'Reviewed',
    text: (
      <>
        {/* <i style={{ color: 'green' }} class="fas fa-user-check mr-3"></i> */}
        Assigned{' '}
      </>
    ),
    value: 2
  },
  {
    key: 'Disqualified',
    text: (
      <>
        {' '}
        {/* <i
          style={{ color: 'red' }}
          class="fas fa-phone-slash mr-3"
        ></i> */}
        Closed{' '}
      </>
    ),
    value: 3
  }
]

const onStatusChangeHandlerUpdate = (e, dat, data) => {
  onChangeInterseted3(e, dat, data)
    .then(res => { })
    .catch(err => {
      CommonNotify('Not able to update lead status')
    })
}
const data_popup = data => {
  return (
    <>
      <div className="dispaly_profile1" style={{ width: '70% !important' }}>
        <div className="profile_boxes1">
          <div className="profile_left1">
            <div className="profile_images1">
              <img src={DummyProfileImage} />
            </div>
            <div className="profile_detail1">
              <p style={{ marginTop: 7 }}>
                {data.customerName !== null && data.customerName !== 'null'
                  ? data.customerName
                  : ''}
              </p>
            </div>
          </div>
        </div>
        <div className="dispaly_profile_detail1">
          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              ID : <span>{data.id} </span>
            </pre>
          </div>

          {data.phone_number &&
            data.phone_number !== '' &&
            data.phone_number !== null ? (
            <div className="profile_content1">
              <div className="call_icon1">
                <img src={smallcall} style={{ height: '130px' }} />
              </div>
              <pre>
                Contact : <span>{data.phone_number}</span>
              </pre>
            </div>
          ) : (
            ' '
          )}
          {data.email && data.email !== 'null' ? (
            <div className="profile_content1">
              <div className="call_icon1">
                <img src={smallEmail} style={{ height: '130px' }} />
              </div>
              <pre>
                Email : <span>{data.email} </span>
              </pre>
            </div>
          ) : (
            ' '
          )}

          {data && (
            <>
              {data.company_name && data.company_name !== null ? (
                <div className="profile_content1">
                  <div className="call_icon1">
                    <Icon style={{ color: '#fff' }} name="trademark" />
                  </div>
                  <pre>
                    Company : <span>{data.company_name} </span>
                  </pre>
                </div>
              ) : (
                ' '
              )}

              {data.country && data.country !== null ? (
                <div className="profile_content1">
                  <div className="call_icon1">
                    <Icon style={{ color: '#fff' }} name="flag" />
                  </div>
                  <pre>
                    Country : <span>{data.country}</span>
                  </pre>
                </div>
              ) : (
                ' '
              )}

              {data.ip_address && data.ip_address !== null ? (
                <div className="profile_content1">
                  <div className="call_icon1">
                    <Icon style={{ color: '#fff' }} name="world" />
                  </div>
                  <pre>
                    IP : <span>{data.ip_address}</span>
                  </pre>
                </div>
              ) : (
                ' '
              )}
            </>
          )}

          {data.type === 'LIVE_CALL' ? (
            <div className="display_button1">
              <div className="row">
                <a
                  style={{
                    height: '30px',
                    padding: '3px 10px',
                    width: '100px'
                  }}
                  className="call_set1 col"
                // onClick={() => onCallRequest(data.id)}
                >
                  <img src={callicons} style={{ maxHeight: '15px' }} />
                </a>
                <a
                  style={{
                    height: '30px',
                    padding: '3px 10px',
                    width: '100px'
                  }}
                  className="mail_set1 col"
                // onClick={() => setOpen(true)}
                >
                  <img src={emailicon} style={{ maxHeight: '15px' }} />
                </a>
              </div>
            </div>
          ) : (
            ' '
          )}
        </div>
      </div>
    </>
  )
}
const data_popup2 = data => {
  return (
    <>
      <div className="dispaly_profile1">
        <div className="profile_boxes1">
          <div className="profile_left1">
            <div className="profile_images1">
              {/* <img src={DummyProfileImage} /> */}
            </div>
            <div className="profile_detail1">
              <p style={{ marginTop: 7 }}>Call Information</p>
            </div>
          </div>
        </div>
        <div className="dispaly_profile_detail1">
          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Direction : <span>{data.direction ? data.direction : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Duration : <span>{data.duration} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Type :{' '}
              <span>{data.type ? data.type.replace('_', ' ') : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Team : <span>{data.team_name ? data.team_name : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Agent : <span>{data.agent ? data.agent : ''} </span>
            </pre>
          </div>
          {data?.type === 'SCHEDULE_CALL' && (
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Scheduled Status :{' '}
                <span>
                  {data.schedule_call_status ? data.schedule_call_status : ''}{' '}
                </span>
              </pre>
            </div>
          )}

          {data.final_status !== 'completed' ? (
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Failure Reason :{' '}
                <span>{data.failure_reason ? data.failure_reason : ''} </span>
              </pre>
            </div>
          ) : (
            <div></div>
          )}

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Source : <span>{data?.source ? data?.source : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Message :{' '}
              <span>{data?.widget_message ? data?.widget_message : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Team name : <span>{data?.team_name ? data?.team_name : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Score : <span>{data?.score ? data?.score : ''} </span>
            </pre>
          </div>

          <div className="profile_content1">
            <div className="call_icon1">
              <span style={{ color: '#fff' }}>#</span>
            </div>
            <pre>
              Final Status : <span>{data?.final_status} </span>
            </pre>
          </div>

          {/* <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
              Technical Code : <span>{data?.tech_code ? data?.tech_code : ''} </span>
              </pre>
            </div>        */}
        </div>
      </div>
    </>
  )
}

const Card = ({ card, index, setSelectedId, getLeads }) => {
  const [, ref] = useDrag({
    type: ColumnTypes.CARD,
    item: { card, index }
  })
  const [openMenu, setOpenMenu] = useState(null)
  const closeLead = async id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
    await Axios.post(url, {
      lead_id: id,
      status: parseInt(card?.status) === 3 ? 1 : 3
    })
      .then(res => {
        getLeads()
        CommonNotify('Updated successfully', 'success')
      })

      .catch(err => { })
  }
  const archiveLead = async id => {
    if (card?.is_archived) {

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/flags?flag=32`
      await Axios.delete(url)
        .then(res => {
          getLeads()
          CommonNotify('Updated successfully', 'success')
        })
    }
    else {

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/flags`
      await Axios.post(url, {
        flag: 32
      })
        .then(res => {
          getLeads()
          CommonNotify('Updated successfully', 'success')
        })
    }
  }

  return (
    <>
      {card?.type ? (
        <div
          className="card"
          ref={ref}
          style={{ border: parseInt(card?.status) === 3 ? '1px solid red' : card?.is_archived ? '1px solid orange' : '' }}
          onClick={() => card?.type && setSelectedId(card?.id)}
        >
          <div title={card?.type || ''} className="card-title">
            <Tooltip title={'Lead'}>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: 'lightblue',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></div>
            </Tooltip>
            <p className="mb-0" style={{ fontWeight: 600 }}>
              {card?.id}
            </p>
            {/* {!card.isHoverDisplay && card.contact ? (
          <Popup
            className="profile_popup"
            content={() => data_popup()}
            size="mini"
            position="bottom left"
            flowing
            hoverable
            trigger={<p>{getContact(card.contact, card.id)}</p>}
          />
        ) : ( */}
            {/* <p>
            {card?.original_source}
          </p> */}

            {/* )} */}
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'end',
                gap: '15px'
              }}
            >
              {card.owner?.name ? (
                <Tooltip title={card.owner?.name}>
                  <Avatar className="avtar-root">
                    {`${card.owner?.name?.split(' ')?.[0]?.[0]}${card.owner?.name?.split(' ')?.[1]?.[0]
                      }`.toUpperCase()}
                  </Avatar>
                </Tooltip>
              ) : null}
              <img
                src={
                  card?.type === 'LIVE_CALL' || card?.type === 'DIGITAL_CALL'
                    ? incomingIcon
                    : card?.type === 'LEAVE_MESSAGE'
                      ? leadsIcon
                      : card?.type === 'SCHEDULE_CALL'
                        ? scheduleIcon
                        : card?.type === 'OUTBOUND_CALL'
                          ? outboundIcon
                          : letterIcon
                }
                alt="icon"
                width={20}
                height={20}
                style={{ marginRight: 4 }}
              />
              <Popup
                trigger={
                  <Image
                    src={checkIcon(card?.schedule_call_status)}
                    className={'rightPopUp'}
                    style={{ width: 20, height: 20 }}
                  />
                }
                position="top center"
                size="mini"
                flowing
                hoverable
                content={() => data_popup2(card)}
              >
                {/* {data.call_status
                    ? data.call_status.replace(/_/g, ' ')
                    : 'Call status not assigned'} */}
              </Popup>
              <MoreVertIcon
                aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={event => {
                  event.stopPropagation()
                  // setSelected(column)
                  setOpenMenu(event.currentTarget)
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginTop: '14px'
            }}
          >
            <div>
              <p className="mb-0" style={{ fontWeight: 600 }}>{`${getContact(
                card.contact_name || card?.customer_name,
                card.id
              ) || ''}`}</p>
              <p className="mb-0">{card?.email || ''}</p>
              <p className="mb-0">{card?.phone_number || ''}</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="card"
          ref={ref}
          onClick={() =>
            setSelectedId(card?.id)
          } /* style={{ backgroundColor:'#fff4e4'}} */
        >
          <div title={card?.type || ''} className="card-title">
            <Tooltip title={'Conversation'}>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: 'orange',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></div>
            </Tooltip>
            <p className="mb-0" style={{ fontWeight: 600 }}>
              {card?.id}
            </p>
            {/* {!card.isHoverDisplay && card.contact ? (
          <Popup
            className="profile_popup"
            content={() => data_popup()}
            size="mini"
            position="bottom left"
            flowing
            hoverable
            trigger={<p>{getContact(card.contact, card.id)}</p>}
          />
        ) : ( */}
            {/* <p>
            {card?.original_source}
          </p> */}

            {/* )} */}
            {/* <div style={{ display: 'flex', flex: 1, justifyContent: "end", gap: "15px" }}>

      {card?.state}
          </div> */}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginTop: '14px'
            }}
          >
            <div>
              <p className="mb-0" style={{ fontWeight: 600 }}>
                {card?.contact?.name}
              </p>
              <p className="mb-0">{card?.contact?.email || ''}</p>
              <p className="mb-0">
                {card?.contact?.phone_number || card?.friendly_name}
              </p>
            </div>
          </div>
          {/* <input
                type="text"
                value={card.customer_name}
                onChange={(e) => editCard(card.id, e.target.value)}
            /> */}
        </div>
      )}
      <Menu
        // id="demo-positioned-menu"
        // aria-labelledby="demo-positioned-button"
        className="stage-popup"
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => setOpenMenu(null)}
      >
        <MenuItem
          onClick={() =>
            archiveLead(card?.id)
          }
        >
          Archive {card?.is_archived ? <DoneIcon /> : ''}
        </MenuItem>
        <MenuItem
          onClick={() =>
            closeLead(card?.id)
          }
        >
          Close {parseInt(card?.status) === 3 ? <DoneIcon /> : ''}
        </MenuItem>
      </Menu>
    </>
  )
}

const Column = ({
  key,
  column,
  cards,
  moveCard,
  selected,
  setSelected,
  createCard,
  editCard,
  index,
  columns,
  editColumn,
  newColumn,
  setNewColumn,
  editStage,
  openMenu,
  setOpenMenu,
  setEditColumn,
  updateStage,
  ...rest
}) => {
  const [, ref] = useDrop({
    accept: ColumnTypes.CARD,
    drop(item) {
      moveCard(item.card, column.id)
    }
  })

  return (
    <div className="column" ref={ref}>
      <div className="column-header">
        {column?.id === editColumn ? (
          <input
            value={newColumn}
            onChange={e => setNewColumn(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                editStage(index)
              }
            }}
          />
        ) : (
          <p>
            {column.title} {`(${column?.cards?.length || 0})`}
          </p>
        )}

        <MoreVertIcon
          aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={event => {
            setSelected(column)
            setOpenMenu(event.currentTarget)
          }}
        />
        <Menu
          // id="demo-positioned-menu"
          // aria-labelledby="demo-positioned-button"
          className="stage-popup"
          anchorEl={openMenu}
          open={!!openMenu}
          onClose={() => setOpenMenu(null)}
        >
          <MenuItem
            onClick={() => {
              setNewColumn(selected?.title)
              setEditColumn(selected?.id)
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() =>
              updateStage({
                action: 'delete',
                array: columns
                  ?.filter((obj, ind) => obj?.id != selected?.id)
                  ?.map(val => val?.title)
              })
            }
          >
            Delete <i className="fa fa-trash" style={{ color: 'red' }} />
          </MenuItem>
        </Menu>
      </div>
      <div className="column-cards">
        {cards.map((card, index) => (
          <Card
            setSelected={setSelected}
            key={card.id}
            card={card}
            index={index}
            editCard={editCard}
            {...rest}
          />
        ))}
      </div>
    </div>
  )
}
const CardView = ({ pipeline, getFunnels }) => {
  const [addNew, setAddNew] = useState(false)
  const [stageArray, setStageArray] = useState([])
  const [newColumn, setNewColumn] = useState('')
  const [changeNew, setChangeNew] = useState(null)
  const [columns, setColumns] = useState([])
  const [openMenu, setOpenMenu] = useState(null)
  const [editColumn, setEditColumn] = useState(null)
  const [selected, setSelected] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const [updatedList, SetUpdatedList] = useState([])
  const [leadModal, setLeadModal] = useState(false)
  const [enableChecked, setEnableChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [leadOwner, setLeadOwner] = useState([])
  const [settingPipeline, setSettingPipeline] = useState(null)
  const [managePipeline, setManagePipeline] = useState({
    edit: null,
    add: null,
    data: null
  })
  const [deletePipeline, setDeletePipeline] = useState(null)

  const [funnel, setFunnel] = useState(null)
  const getWidget = async () => {
    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`
    await Axios.get(url)
      .then(res => {
        const data = res.data.data.scores_tags
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  useEffect(() => {
    setFunnel(pipeline?.filter(obj => obj?.is_default)?.[0])
  }, [pipeline])

  useEffect(() => {
    if (funnel) {
      setStageArray(funnel.stages || [])
      const updateStageScore = funnel?.stages
        ? funnel?.stages?.map((item, index) => {
          return {
            id: index,
            title: item,
            cards: []
          }
        })
        : []
      setColumns(updateStageScore)
      getLeads(updateStageScore, false, funnel)
    }
  }, [funnel])


  // useEffect(() => {
  //   getLeads(columns, true)
  // }, [enableChecked])

  const getLeads = async (existingColumn = columns, loader, newFunnel = funnel) => {
    if (loader) {
      setLoading(true)
    }
    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel/${newFunnel?.id}/leads`
    Axios.get(url)
      .then(res => {
        const data = res.data.data
        const col = existingColumn?.map((obj, index) => ({ ...obj, cards: [] }))
        let coll = []
        const dd = data?.forEach(async obj => {
          const stageCol = col?.filter(val => val?.title === obj?.stage)?.[0]
          if (stageCol) {
            const updated = stageCol?.cards?.push(obj)
          }
          coll = col?.map((obj, index) =>
            obj?.title === stageCol?.title ? stageCol : obj
          )
          await setColumns(coll)
        })
        setLoading(false)
        getConversation(coll, loader, funnel)
      })

      .catch(err => {
        getConversation(columns, loader, funnel)

        setLoading(false)

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }
  const getConversation = async (
    existingColumn = columns,
    loader,
    newFunnel = funnel
  ) => {
    if (loader) {
      setLoading(true)
    }
    const w_id = await localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel/${newFunnel?.id}/conversations`
    Axios.get(url)
      .then(res => {
        const data = res.data.data
        const col = existingColumn
        const dd = data?.forEach(obj => {
          const stageCol = col?.filter(val => val?.title === obj?.stage)?.[0]
          if (stageCol) {
            const updated = stageCol?.cards?.push(obj)
          }
          setColumns(
            col?.map((obj, index) =>
              obj?.title === stageCol?.title ? stageCol : obj
            )
          )
        })
        setLoading(false)
      })

      .catch(err => {
        setLoading(false)

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  const saveLeads = async data => {
    const data1 = columns?.map((obj, index) => ({ ...obj, cards: [] }))

    setColumns(data1)
    return SetUpdatedList(data)
  }
  useEffect(() => {
    const dd = updatedList?.forEach(obj => {
      const stageCol = columns?.filter(val => val?.title === obj?.stage)?.[0]
      if (stageCol) {
        const updated = stageCol?.cards?.push(obj)
      }
      setColumns(
        columns?.map((obj, index) =>
          obj?.title === stageCol?.title ? stageCol : obj
        )
      )
    })
  }, [updatedList])

  const updateStage = async ({ action, array, updatedFunnel }) => {
    const id = await localStorage.getItem('widget_id')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel/${funnel?.id}`
    Axios.put(url, {
      name: updatedFunnel ? updatedFunnel?.name : funnel?.name,
      stages: array || funnel?.stages || []
    })
      .then(res => {
        getWidget()
        setEditColumn(null)
        setManagePipeline({})
        setOpenMenu(null)
        setSelected(null)
        getFunnels()
        if (action === 'delete') {
          CommonNotify('Deleted successfully', 'success')
        } else if (action === 'edit') {
          CommonNotify('Updated successfully', 'success')
        } else {
          CommonNotify('Added successfully', 'success')
        }
      })

      .catch(err => { })
  }
  const addFunnel = async ({ updatedFunnel }) => {
    const id = await localStorage.getItem('widget_id')

    if (updatedFunnel?.name) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel`
      Axios.post(url, {
        name: updatedFunnel?.name,
        stages: ['New', 'SAL', 'SQL', 'Opportunity', 'Lost']
      })
        .then(res => {
          setManagePipeline({})
          getFunnels()
          CommonNotify('Added sucessfully')
        })

        .catch(err => { })
    } else {
      CommonNotify('Please fill the name')
    }
  }
  const onDeletePipeline = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel/${deletePipeline}`
    Axios.delete(url)
      .then(res => {
        setManagePipeline({})
        setDeletePipeline(null)
        getFunnels()
        CommonNotify('Deleted sucessfully')
      })

      .catch(err => { })
  }

  useEffect(() => {
    document.title = 'Pipeline'

    getWidget()
    getOwner()
  }, [])

  const moveCard = (draggedCard, targetColumnId) => {
    const updatedColumns = columns?.map(column => {
      if (column.id === targetColumnId) {
        column.cards.push(draggedCard)
      } else {
        column.cards = column.cards.filter(card => card.id !== draggedCard.id)
      }
      return column
    })

    updateLeadStage(
      columns?.[targetColumnId]?.title,
      draggedCard?.id,
      draggedCard?.type ? 'leads' : 'conversation'
    )
    setColumns(updatedColumns)
  }
  const updateLeadStage = async (newCol, id, type) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/${type}/${id}/funnel`
    Axios.put(url, { stage: newCol, funnel_id: funnel?.id })
      .then(res => { })

      .catch(err => { })
  }

  const createCard = (columnId, text) => {
    const newCard = { id: Date.now(), text }
    const updatedColumns = columns?.map(column => {
      if (column.id === columnId) {
        column.cards.push(newCard)
      }
      return column
    })
    setColumns(updatedColumns)
  }
  const getOwner = async () => {
    const owner = [
      {
        key: 'unassign owner',
        text: 'Unassign owner',
        value: null
      }
    ]
    const allMembers = []
    const allMembers2 = []

    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin
        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        allMembers.push({
          key: 0,
          text: 'All Agents',
          value: 0
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        allMembers2.push({
          key: 0,
          text: 'All Owners',
          value: 0
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })

          allMembers2.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
        })

        setLeadOwner([...owner])
      })
      .catch(err => { })
  }
  const editCard = (cardId, newText) => {
    const updatedColumns = columns?.map(column => {
      column.cards = column.cards.map(card => {
        if (card.id === cardId) {
          card.text = newText
        }
        return card
      })
      return column
    })
    setColumns(updatedColumns)
  }
  const title = {
    // type: 'image',
    //   titleOne: leadLogo,
    titleTwo: 'Pipeline'
  }

  const addNewStage = () => {
    columns.push({ id: columns?.length + 1, title: changeNew, cards: [] })
    updateStage({ action: 'add', array: [...stageArray, changeNew] })
    setAddNew(false)
  }
  const editStage = index => {
    const newCol = columns?.map((obj, i) =>
      i === index ? { ...obj, title: newColumn } : { ...obj }
    )
    setColumns(newCol)
    updateStage({ action: 'edit', array: newCol?.map(val => val?.title) })
  }

  return (
    <>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Title data={title} />
      <Navbar />
      <div className="card-view-container home_page">
        {/* <div className="homecontent-wrapper"> */}
        <div className="d-flex align-items-center">
          <Dropdown
            className="card-boxes-right-text pipeline-dropdown"
            options={pipeline?.map((obj, index) => ({
              key: index + 1,
              text: obj?.name,
              value: obj?.name
            }))}
            placeholder="Select Pipeline"
            value={funnel?.name}
            onChange={(e, dat) =>
              setFunnel(pipeline?.filter(obj => obj?.name === dat?.value)?.[0])
            }
          />

          <SettingsIcon
            style={{ marginLeft: '15px', cursor: 'pointer' }}
            aria-controls={settingPipeline ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={settingPipeline ? 'true' : undefined}
            onClick={event => {
              setSettingPipeline(event.currentTarget)
            }}
          />
          <Menu
            // id="demo-positioned-menu"
            // aria-labelledby="demo-positioned-button"
            className="stage-popup"
            anchorEl={settingPipeline}
            open={!!settingPipeline}
            onClose={() => setSettingPipeline(null)}
          >
            <MenuItem
              onClick={() => {
                setManagePipeline({ edit: true, data: funnel })
                setSettingPipeline(null)
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (funnel?.is_default) {
                  CommonNotify(`You can't delete default pipeline`)
                  setSettingPipeline(null)
                } else {
                  setDeletePipeline(funnel?.id)
                  setSettingPipeline(null)
                }
              }}
            >
              Delete <i className="fa fa-trash" style={{ color: 'red' }} />
            </MenuItem>
          </Menu>
          <CommonButtons
            style={{ marginLeft: '15px', height: '35px' }}
            content="Add New Pipeline"
            background="blue"
            onClick={() => setManagePipeline({ add: true })}
          />
          <CommonButtons
            content="Add  New Lead"
            background="blue"
            style={{ height: '35px' }}
            onClick={() => setLeadModal(true)}
          />
          <CardFilters
            setLeadModal={setLeadModal}
            saveLeads={saveLeads}
            enableChecked={enableChecked}
            setEnableChecked={setEnableChecked}
            funnel={funnel}
            existingColumn={columns}
            getConversation={getConversation}
            setColumns={setColumns}
            pipeline={pipeline}
          />
        </div>

        <DndProvider backend={HTML5Backend}>
          <div className="board">
            {columns?.map((column, index) => (
              <>
                <Column
                  setSelected={setSelected}
                  column={column}
                  cards={column.cards}
                  moveCard={moveCard}
                  createCard={createCard}
                  editCard={editCard}
                  index={index}
                  updateStage={updateStage}
                  columns={columns}
                  {...{
                    key: column?.id,
                    selected,
                    setSelected,
                    editColumn,
                    newColumn,
                    setNewColumn,
                    editStage,
                    openMenu,
                    setOpenMenu,
                    setEditColumn,
                    updateStage,
                    setSelectedId,
                    getLeads
                  }}
                />
              </>
            ))}
            <div className="column add-stage-column">
              {addNew ? (
                <input
                  placeholder="Enter the stage name here"
                  onChange={e => setChangeNew(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      addNewStage()
                    }
                  }}
                />
              ) : (
                <div onClick={() => setAddNew(true)} className="column-header">
                  <p>
                    <PlusIcon /> Add Stage
                  </p>
                </div>
              )}
            </div>
          </div>
        </DndProvider>
        {leadModal && (
          <AddNewLead
            setModal={e => {
              setLeadModal(e)
            }}
            hideScreens={true}
            reloadTable={() => getLeads()}
          />
        )}
        <RightPaneContainer
          selectedId={selectedId}
          setSelectedId={setSelectedId}
        />
        <Modal
          style={{ width: '22%' }}
          open={!!(managePipeline?.add || managePipeline?.edit)}
          onClose={() => setManagePipeline({})}
        >
          <Modal.Header>
            {managePipeline?.add ? 'Add new' : 'Edit Pipeline'}
          </Modal.Header>
          <Modal.Content>
            <div
              style={{
                padding: '20px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-evenly'
              }}
            >
              <label>Name : </label>
              <CommonInput
                value={managePipeline?.data?.name || managePipeline?.data?.name}
                onChange={e =>
                  setManagePipeline({
                    ...managePipeline,
                    data: { ...managePipeline?.data, name: e.target.value }
                  })
                }
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <CommonButtons onClick={() => setManagePipeline({})}>
              Cancel
            </CommonButtons>
            <CommonButtons
              background="blue"
              onClick={() =>
                managePipeline?.add
                  ? addFunnel({ updatedFunnel: managePipeline?.data })
                  : updateStage({
                      action: managePipeline?.add ? 'add' : 'edit',
                      array: managePipeline?.data?.stages,
                      updatedFunnel: managePipeline?.data
                    })
              }
            >
              {managePipeline?.add ? 'Add' : 'Update'}
            </CommonButtons>
          </Modal.Actions>
        </Modal>
        <Modal
          className="local-number-modal"
          open={deletePipeline}
          onClose={e => setDeletePipeline(null)}
        >
          <Modal.Content>
            <div className="modal-content">
              <p style={{ marginTop: 20 }}>Are you sure you want to delete?</p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <CommonButtons
                  onClick={() => setDeletePipeline(false)}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButtons
                  onClick={onDeletePipeline}
                  type="button"
                  content="Confirm"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
        {/* {selectedId ? (
        <LeadDetailModal
          {...{ selectedId,getLeads, leadOwner,setSelectedId, lead: leads?.filter((obj) => obj?.id === selectedId)?.[0] }}
        />
                <RightPaneContainer
                selectedId={selectedId}
              />
                <LeadDetailModal
          {...{ selectedId, setSelectedId, lead: leads?.filter((obj)=>obj?.id===selectedId)?.[0] }}
                />
              ) : (
                  ''
              )} */}
      </div>
      {/* </div> */}
    </>
  )
}
const mapStateToProps = state => ({
  pipeline: state.home.pipeline
})

export default connect(mapStateToProps, { getFunnels })(CardView)
