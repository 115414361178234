import React, { Component, forwardRef } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { MentionsInput, Mention } from 'react-mentions'
import RecentVisits from './RecentVisits'
import deleteIcon from '../assets/images/delete-icon.png'
import EverGreenSelect from '../common/EvergreenSelect'
// import checkIcon from '../assets/images/checkList.png'
import callNewIcon from '../assets/images/sms-new.png'
import smsNewIcon from '../assets/images/telephone-new.png'
import callbackIcon from '../assets/images/callback-icon.png'
import rightArrow from '../assets/images/right-arrow.png'
import leftArrow from '../assets/images/left-arrow.png'
import { billingCountryList } from '../helpers/billingCountryListTwo'
import EvergreenSingleSelect from '../common/EvergreenSingleSelect'
import ButtonEvergreen from '../common/EvergreenButton'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Pusher from 'pusher-js'

import { withRouter } from 'react-router'

import { getDialer } from '../actions/dialer'

import {
  InfoSignIcon,
  EditIcon,
  PhoneIcon,
  EnvelopeIcon,
  AnnotationIcon
} from 'evergreen-ui'

import {
  Dimmer,
  Dropdown,
  Loader,
  Popup,
  Menu,
  Label,
  Modal,
  Image,
  Input,
  Button
} from 'semantic-ui-react'
import moment from 'moment-timezone'
import UtmData from './UtmData'
import Callset from '../assets/images/call_set.png'
import DummyProfileImage from '../assets/images/dummy_profile.webp'
import callicons from '../assets/images/call_icons.png'
import emailicon from '../assets/images/mail_icons.png'
import editLead from '../assets/images/Dashboard-35.png'
import convertContact from '../assets/images/convertContact.png'

import CallLogs from './CallLogs'
import LeadInfo from './LeadInfo'
import Leadform from './LeadForm'
import AgentData from './AgentData'
import Calldata from './Calldata'
import VisitData from './VisitData'
import ContactData from './ContactData'
import { CommonNotify } from '../common/CommonNotify'

import LeadPageModal from '../common/LeadPageModal'
import {
  onCallRequest,
  onStatusChangeHandler,
  _getLeadActivities,
  _getLeadNotes,
  _onTagsSaveHandler,
  _createActivity,
  _fetchActivileadAPty,
  _fetchActivity,
  getLeadOwner
} from '../config/leadAPI'
import SendMessageModal from '../common/SendMessageModal'
import UpdateLeadModal from '../common/UpdateLeadModal'
import CreateCallModal from '../common/CreateCallModal'
import CommonButtons from '../common/CommonButtons'
//import LeadActivities from '../common/LeadActivities'

import LeadActivities from '../common/LeadActivitiesTab'

import { getCallForwadingSettings } from '../actions/settings'
import Insights from './Insights'
import CallbackModal from '../common/CallbackModal'
import RejectPopup from '../components/leads/leadRightPane/RejectPopup'

const callDataDefault = {
  phone_number: '',
  customer_name: '',
  email: '',
  ip_address: '',
  company_domain: '',
  duration: 0,
  final_status: '',
  tags: [],
  member: {
    first_name: '',
    last_name: ''
  },
  location: {
    city: '',
    country: ''
  },
  owner: {
    temp_phone_number: '',
    company_domain: ''
  },
  source: '',
  score: 0,
  id: '',
  date: ''
}

const statusOption = [
  {
    key: 'Awaiting Review',
    text: 'Pending',
    value: '1',
    label: 'Pending'
  },
  { key: 'Reviewed', text: 'Assigned', value: '2', label: 'Assigned' },
  {
    key: 'Disqualified',
    text: 'Closed',
    value: '3',
    label: 'Closed'
  },
  { key: 'Qualified', text: 'Qualified', value: '4', label: 'Qualified' }
]

const checkIcon =
  process.env.REACT_APP_BASE_APP_URL +
  '/assets/lead_details_icons/Lead popup edited-33.svg'

class CallDashboard extends Component {
  state = {
    callBackModal: false,
    cancelReject: false,
    selectedTags: [],
    leadInfo: null,
    lead_interest: null,
    buttonLoader: false,
    deleteModal: false,
    banModal: false,
    addContactModal: false,
    defaultStage: null,
    activityType: '',
    activityTitle: '',
    showAddActivity: false,
    showAddNotes: true,
    recentPageHistory: [],
    showActivity: true,
    show: false,
    dataTag: null,
    open: false,
    openCall: false,
    openLead: false,
    commentBoxOpen: true,
    callData: callDataDefault,
    messages: [],
    apiLoaded: false,
    isLoading: true,
    isBoxVisible: true,
    leadScore: [],
    tagsData: [],
    stageData: [],
    interestData: [],
    tagsDataDefault: [],
    updateTagsData: [],
    leadScoreDefault: '',
    leadStageData: '',
    defaultCollaborator: '',
    defaultOwner: '',
    collaboratesData: [],
    interestedData: [],
    defaultLeadMembers: [],
    collaborationAdminData: null,
    assignOption: [],
    lead_Id: null,
    dropDownData: [],
    tagTeamData: [],
    open: false,
    interseted: null,
    client_name: '',
    getNotes: [],
    inEditMode: false,
    btnLoading: false,
    mentionUser: [],
    ownerName: '',
    leadActivities: [],
    startDate: new Date(),
    noteUpdate: false,
    //
    name: 'React',
    value: '',
    mentionData: null,
    indexNote: null,
    leadUpdated: false,
    activityUpdated: false
  }

  Collaborators = {
    lable: 'Give team member access',
    header: 'Add Collaborators',
    btnTitle: 'Update'
  }
  Tags = {
    lable: 'New tag',
    header: 'Tag Lead',
    btnTitle: 'Update'
  }

  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({
      value: newValue,
      mentionData: { newValue, newPlainTextValue, mentions }
    })
  }

  componentDidMount = () => {
    document.title = 'Lead Details'
    // const callId = this.getCallId()
    this.intitialFunctionCall()
  }

  intitialFunctionCall = () => {
    const callId = this.props.match.params.id
    this.setState({
      ...this.state,
      lead_Id: callId
    })
    this.getWidget()
    this.getCollaborates()
    this.getUserId()
    this.getOwner()
    if (callId) {
      this.fetchCallData(callId)
      this.getLeadNotes(callId)
      this.getLeadActivities(callId)
      this.getLeadInfo(callId)
      //this._fetchActivity(callId)
    }
    this.fetchSettings()
  }

  getLeadInfo = id => {
    if (id) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
      axios
        .get(url)
        .then(res => {
          this.setState({
            leadInfo: res.data
          })
        })
        .catch(err => {})
    }
  }

  getOwner = async () => {
    const owner = [
      {
        key: 'unassign owner',
        text: 'Unassign owner',
        value: null,
        label: 'Unassign owner'
      }
    ]
    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id,
          label: admin.first_name + ' ' + admin.last_name,
          image: { src: require('../assets/images/owner-assign.png') }
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id,
            label: team.first_name + ' ' + team.last_name,
            image: { src: require('../assets/images/owner-assign.png') }
          })
        })
        this.setState({
          ...this.state,
          assignOption: owner
        })
      })
      .catch(err => {})
  }

  deleteModalView = async bool => {
    this.setState({ deleteModal: bool })
  }

  banModalView = async (type, data, bool) => {
    this.setState({ banModal: bool, selectedType: type, selectedData: data })
  }

  contactModalView = async bool => {
    this.setState({ addContactModal: bool })
  }

  getLeadActivities = (callId = false) => {
    if (!callId) {
      callId = this.getCallId()
    }
    let { leadActivities } = this.state

    _fetchActivity(callId)
      .then(res => {
        leadActivities = [...res]
        const { activityUpdated } = this.state
        this.setState({
          ...this.state,
          leadActivities,
          activityUpdated: !activityUpdated
        })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  onEditHandle(event) {
    this.setState({
      inEditMode: true
    })
  }

  onChangeClientName = e =>
    this.setState({
      ...this.state,
      client_name: e.target.value
    })
  openPopupHandler = () => {
    this.setState({
      ...this.state,
      commentBoxOpen: !this.state.commentBoxOpen,
      showAddNotes: !this.state.showAddNotes
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.setNumbers(
        this.props.callForwadingSettings.data.available_forward_no
      )
    }
  }

  fetchSettings = () => {
    this.setState({ isLoading: false })
    if (this.props.callForwadingSettings) {
      this.setNumbers(
        this.props.callForwadingSettings.data.available_forward_no
      )
    } else {
      this.setState({ isLoading: true })
      this.props.getCallForwadingSettings()
    }
  }

  setNumbers = data => {
    let updateForwardNo = []

    updateForwardNo = data?.map(number => {
      return {
        key: number.phone_number,
        value: number.phone_number,
        text: `LimePhone Number (${number.phone_number})`
      }
    })
    this.setState({ forwardNo: updateForwardNo, dropDownData: updateForwardNo })
  }

  activityHandler = () => {
    this.setState({ ...this.state, showActivity: !this.state.showActivity })
  }

  closePopupHandler = () => {
    this.setState({ commentBoxOpen: false })
  }

  createNotes = async () => {
    const { mentionData } = this.state

    if (mentionData === null) {
      CommonNotify('Please enter text first.')
    } else {
      const { newPlainTextValue, mentions } = mentionData
      var str = ''
      if (mentions.length) {
        var reg = ''
        mentions.map(data => {
          reg += '|' + data.display
        })

        const re = new RegExp(reg.substring(1), 'g')
        str = newPlainTextValue.replace(re, function(matched) {
          const id = mentions.find(obj => obj.display == matched).id
          return '<:' + id + '>'
        })
      } else {
        str = newPlainTextValue
      }

      this.setState({
        ...this.state,
        btnLoading: true
      })
      const { lead_Id } = this.state

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/add-note`

      const postData = {
        content: str
      }
      axios
        .post(url, postData)
        .then(res => {
          this.setState({
            ...this.state,
            value: '',
            mentionData: null
          })
          this.getLeadNotes(lead_Id)
        })
        .catch(error => {})
    }
  }

  editNotes = async () => {
    const { mentionData, indexNote } = this.state

    if (mentionData === null || indexNote === null) {
      CommonNotify('Please enter text first.')
    } else {
      const { newPlainTextValue, mentions } = mentionData
      var str = ''
      if (mentions.length) {
        var reg = ''
        mentions.map(data => {
          reg += '|' + data.display
        })

        const re = new RegExp(reg.substring(1), 'g')
        str = newPlainTextValue.replace(re, function(matched) {
          const id = mentions.find(obj => obj.display == matched).id
          return '<:' + id + '>'
        })
      } else {
        str = newPlainTextValue
      }

      this.setState({
        ...this.state,
        btnLoading: true
      })
      const { lead_Id } = this.state

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/edit-notes`

      const postData = {
        index: indexNote,
        call_notes: str
      }
      axios
        .post(url, postData)
        .then(res => {
          this.setState({
            ...this.state,
            value: '',
            mentionData: null,
            indexNote: null,
            noteUpdate: false
          })
          this.getLeadNotes(lead_Id)
        })
        .catch(error => {})
    }
  }

  getLeadNotes = callId => {
    _getLeadNotes(callId)
      .then(res => {
        this.setState({
          ...this.state,
          btnLoading: false,
          getNotes: Object.entries(res)
        })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  handleOpenLead = lead => {
    window.location.assign(`/leads/${lead}`)
  }

  getCollaborates = async () => {
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    const user = []
    axios
      .get(url)
      .then(res => {
        var defaultIds = []
        var moderateArray = []

        const admin = res.data.data.admin

        moderateArray.push({
          key: admin.id,
          text: `${admin.first_name} ${admin.last_name}`,
          value: admin.id,
          label: `${admin.first_name} ${admin.last_name}`,
          image: {
            avatar: true,
            src: require('../assets/images/new-collab.png')
          }
        })

        res.data.data.members &&
          res.data.data.members.map(item => {
            user.push({
              _id: item.id,
              name: { first: item.first_name, last: item.last_name }
            })
            defaultIds.push(item.id)
            const d = {
              key: item.id,
              text: `${item.first_name} ${item.last_name}`,
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
              image: {
                avatar: true,
                src: require('../assets/images/new-collab.png')
              }
            }
            moderateArray.push(d)
          })

        const finalCollabList = moderateArray
        this.setState({
          collaboratesData: finalCollabList,
          collaborationAdminData: res.data.data.admin.id,
          mentionUser: user
        })
      })
      .catch(err => {})
  }

  getWidget = async () => {
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`

    axios
      .get(url)
      .then(res => {
        localStorage.setItem('widget_active', res?.data?.data?.[0]?.active)
        const updateTagsData = res.data.data[0].tags.map((item, index) => {
          return {
            key: item,
            text: item,
            value: item
          }
        })

        const setInterestedData = res.data.data[0].interested

        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item,
              label: item,
              image: {
                avatar: true,
                src: require('../assets/images/Lead_Score.png')
              }
            }
          }
        )

        const updateLeadScore = res.data.data[0].scores.map((item, index) => {
          return {
            key: index,
            text: item,
            value: item,
            label: item,
            image: {
              avatar: true,
              src: require('../assets/images/Lead_Score.png')
            }
          }
        })

        const updateLeadStage = res.data.data[0].stages.map((item, index) => {
          return {
            key: index,
            text: item,
            value: item,
            label: item,
            image: {
              avatar: true,
              src: require('../assets/images/Lead_Score.png')
            }
          }
        })

        this.setState({
          interestData: [...updateLeadInterest],
          stageData: [...updateLeadStage],
          tagsData: [...updateTagsData],
          leadScore: [...updateLeadScore],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => {})
  }

  getCallId = () => {
    let url = this.props.location.search
    let params = queryString.parse(url)
    if (params.callId) return params.callId
    return 0
  }

  fetchCountryData = () => {
    const token = '7cb27f1b3b4e06'
    const ip = this.state.callData.ip_address

    if (ip) {
      const url = `https://ipinfo.io/${ip}?token=${token}`

      axios
        .get(url)
        .then(res => {
          if (res.data) {
            let callData = {
              ...this.state.callData
            }
            let location = res.data
            callData.location = location
            const time = moment()
              .tz(callData.location.timezone)
              .format('yyyy-MM-DD hh:mm')

            this.setState({
              callData: callData,
              date: time
            })
          }
        })
        .catch(error => {})
    }
  }

  async getUserId() {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/caller-id`
    axios
      .get(url)
      .then(res => {
        let data = []
        data = res.data.data.map(number => {
          return {
            key: number[0],
            value: number[0],
            text: `LimePhone Number (${number[0]})`
          }
        })

        this.setState({
          ...this.state,
          dropDownData: data
        })
      })
      .catch(err => {})
  }

  fetchCallData = async callId => {
    const apiToken = await localStorage.getItem('access_token') // access_token

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-call-by-id?id=${callId}`

    await axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          if (res.data.data.tags == null) {
            res.data.data.tags = []
          }

          let selectedCollab = []

          var PageHistory = []

          if (res.data.data.mentions.length) {
            res.data.data.mentions.map(function(item, index) {
              selectedCollab.push(item.id)
            })
          }

          if (
            res.data.data.trackingData &&
            res.data.data.trackingData.length !== 0
          ) {
            PageHistory = res.data.data.trackingData.browsing_history
              ? res.data.data.trackingData.browsing_history
              : []
          } else {
            PageHistory = []
          }

          const ownerName =
            res.data.data.member?.first_name +
            ' ' +
            res.data.data.member?.last_name
          this.setState(
            prevState => ({
              ...prevState,
              isLoading: false,
              recentPageHistory: PageHistory,
              defaultStage: res.data.data.status
                ? res.data.data.status.toString()
                : '1',
              ownerName: ownerName,
              interseted: res.data.data.interseted,
              lead_interest: res.data.data.lead_interest,
              callData: {
                ...prevState.callData,
                ...res.data.data
              },
              addNoteToggleDataRes: res.data.data.call_notes
                ? res.data.data.call_notes
                : [],
              tagsDataDefault: res.data.data.tags,
              leadScoreDefault: res.data.data.score
                ? res.data.data.score
                : 'Lead Score',
              leadStageData: res.data.data.stage
                ? res.data.data.stage
                : 'Lead Stage',
              defaultCollaborator: selectedCollab,
              defaultOwner: res.data.data.owner
                ? res.data.data.owner.id
                : 'Unassigned Owner'
            }),
            () => {
              this.fetchCountryData()
            }
          )

          this.setState({ apiLoaded: true })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify('Invalid Lead ID')
      })
  }

  getCurrentTime = () => {
    var today = new Date()

    var time = today.getHours() + ':' + today.getMinutes()
    return time
  }

  onClickOpenModal = text =>
    this.setState({ ...this.state, show: true, dataTag: text })
  handleClose = () => this.setState({ ...this.state, show: false })

  // USED FOR TAG LEAD
  onTagsSaveHandler = (e, data) => {
    const { lead_Id, collaborationAdminData, selectedTags } = this.state

    const postData = {
      lead_id: lead_Id,
      customer_id: collaborationAdminData,
      tags: selectedTags
    }
    _onTagsSaveHandler(postData)
      .then(res => {
        const callId = this.state.lead_Id
        if (callId) {
          this.getLeadActivities(callId)
        }
      })
      .catch(err => {
        CommonNotify('Not able to Update Tags')
      })
  }

  changeTags = (e, data) => {
    this.setState({ selectedTags: data.value })
  }

  // USED FOR CHANGE THE LEAD SCORE
  onScoreSaveHandler = async data => {
    const { lead_Id } = this.state
    if (data === 'Lead Score') {
      return
    }
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/is-good`
    const postData = {
      lead_id: lead_Id,
      score: data
    }
    axios
      .post(url, postData)
      .then(res => {
        const callId = this.state.lead_Id
        if (callId) {
          //CommonNotify('Lead Score updated', 'success' )
          this.getLeadActivities(callId)
        }
      })
      .catch(err => {
        //CommonNotify('Not able to update Score')
      })
  }

  getDate = dt => {
    var today = dt
    //var datum = Date.parse(dt);

    today = new Date(today)
    today = today.toISOString().split('T')[0]

    return today + ' 00:00'
  }

  updateFollowup = async date => {
    const { lead_Id } = this.state

    date = this.getDate(date)

    const apiToken = await localStorage.getItem('access_token')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/follow-up-message`
    const postData = {
      lead_id: parseInt(lead_Id),
      due_date: date,
      desktop_reminder: 0
    }
    axios
      .post(url, postData)
      .then(res => {
        const callId = this.state.lead_Id
        if (callId) {
          CommonNotify('Lead followup date updated', 'success')
        }
      })
      .catch(err => {
        CommonNotify('Not able to update followup date')
      })
  }

  onStageSaveHandler = async data => {
    if (data === 'Lead Stage') {
      return
    }
    const { lead_Id } = this.state
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/change-lead-stage`
    const postData = {
      lead_id: lead_Id,
      stage: data
    }

    axios
      .post(url, postData)
      .then(res => {
        //CommonNotify('Done successfully', 'success')
      })
      .catch(err => {
        // CommonNotify('Not able to update  Stage')
      })
  }

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  onUpdateInterest = async data => {
    const { lead_Id, collaborationAdminData } = this.state
    if (!data) {
      return
    }
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`
    const postData = {
      lead_interest: data
    }
    axios
      .post(url, postData)
      .then(res => {
        //CommonNotify('Lead Interest updated successfully.', 'success')
      })
      .catch(err => {
        //CommonNotify('Some error occured.')
      })
  }

  onUpdateRequest = async data => {
    if (data.phone_number) {
      if (data.phone_number.length < 10) {
        return CommonNotify('Validation error invalid phone number', 'warning')
      }
    }

    const { lead_Id, collaborationAdminData } = this.state
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`
    const postData = {
      customer_name: data.customer_name,
      phone_number: data.phone_number,
      email: data.email,
      notes: data.interest,
      country: data.country,
      role: data.role,
      company_name: data.company,
      industry: data.industry,
      employees: data.companySize,
      lead_interest: data.lead_interest
    }
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead info updated', 'success')
        this.setState({ openLead: false })
        const callId = this.getCallId()
        this.fetchCallData(callId)
        this.getLeadNotes(callId)
        this.getLeadActivities(callId)
      })
      .catch(err => {
        CommonNotify('Cant Update Lead Detail')
      })
  }

  onCollaboratorsHandler = async data => {
    const {
      lead_Id,
      collaborationAdminData,
      defaultOwner,
      defaultCollaborator
    } = this.state

    const value = data

    var filtered = value.filter(function(e) {
      return this.indexOf(e) < 0
    }, defaultCollaborator)

    let stringParams = value.map((item, index) => {
      return `&to[${index}]=${item}`
    })

    const apiToken = await localStorage.getItem('access_token')
    const user_id = await localStorage.getItem('id')

    var url = ''
    if (stringParams.length >= 1) {
      stringParams = stringParams.join('')
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}${stringParams}`
    } else {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/mention-member?from=${user_id}&content=${lead_Id}`
    }

    const postData = {}
    axios
      .post(url, postData)
      .then(res => {
        this.getLeadActivities()
        //CommonNotify('Lead collaborator saved ', 'success')
      })
      .catch(err => {
        // CommonNotify('Some error occured.')
      })
  }

  isModalClose = () => {
    this.setState({
      ...this.state,
      open: false
    })
    this.getLeadActivities(this.state.lead_Id)
  }

  isModalCallClose = () => {
    this.setState({
      ...this.state,
      openCall: false
    })
  }

  isModalLeadClose = () => {
    this.setState({
      ...this.state,
      openLead: false
    })
  }

  isModalLeadOpen = () => {
    this.setState({
      ...this.state,
      openLead: true
    })
  }

  isModalOpen = () => {
    this.setState({
      ...this.state,
      open: true
    })
  }

  isModalOpen = () => {
    this.setState({
      ...this.state,
      openCall: true
    })
  }

  onChangeAssign = async data => {
    if (data === '' || data?.length === 0 || data === 'Unassigned Owner') {
      return
    }

    const { lead_Id } = this.state
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/set-owner`
    const postData = {
      lead_id: lead_Id,
      user_id: data
    }

    axios
      .post(url, postData)
      .then(res => {
        const callId = this.state.lead_Id
        if (callId) {
          //CommonNotify('Lead Assigned', 'success')
          this.getLeadActivities(callId)
        }
      })
      .catch(err => {
        //CommonNotify('Not able to Assigned')
      })
  }

  handleItemClick = async (e, { name }, interseted) => {
    const { lead_Id } = this.state
    this.setState({ ...this.state, interseted: interseted })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
    const postData = {
      lead_id: lead_Id,
      interested: interseted
    }

    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Done successfully', 'success')
      })
      .catch(err => {
        CommonNotify('Not able to update  Good Fit')
      })
  }

  onDeleteLeadNotes = async (id, index) => {
    const apiToken = await localStorage.getItem('access_token')
    const { lead_Id } = this.state

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-notes`

    const postData = {
      id: index,
      lead_id: lead_Id
    }

    axios
      .post(url, postData)
      .then(res => {
        this.getLeadNotes(lead_Id)
        CommonNotify('Lead note deleted', 'success')
      })
      .catch(err => {
        CommonNotify('Not able to update  Good Fit')
      })
  }

  showActivityUpdate = (type, title) => {
    this.setActivityTitle(title)
    this.setActivityType(type)
    this.setState({ showAddActivity: true })
  }

  showActivityUpdateNew = (type, title) => {
    this.setState({ showAddActivity: true })
  }

  setActivityType = type => {
    this.setState({ activityType: type })
  }

  setActivityTitle = title => {
    this.setState({ activityTitle: title })
  }

  renderEditForm() {
    if (this.state.edit) {
      return (
        <form onSubmit={this.onUpdateHandle.bind(this)}>
          <input
            type="text"
            name="updatedItem"
            className="item"
            defaultValue={this.state.title}
          />
          <button className="update-add-item">Update</button>
        </form>
      )
    }
  }

  onDeleteLeadHandler = async () => {
    this.setState({ isLoading: true, deleteModal: false })
    const { lead_Id } = this.state
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    const head = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
    var postId = [lead_Id]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        this.setState({ isLoading: false })
        CommonNotify('Lead Deleted Successfully', 'success')
        this.props.history.push('/leads')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        CommonNotify('Not able to Delete Lead')
      })
  }

  setStatusLead = async id => {
    const data = {
      value: id
    }
    onStatusChangeHandler('', data, `#${this.state.lead_Id}`)
  }
  addContactLeadHandler = async () => {
    this.setState({
      isLoading: false,
      deleteModal: false,
      addContactModal: false,
      buttonLoader: true
    })
    const { lead_Id } = this.state
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/contact`
    const head = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }

    axios
      .post(URL, {})
      .then(res => {
        let callData = { ...this.state.callData }
        callData.contact = res.data.data
        this.setState({ isLoading: false, buttonLoader: false, callData })
        CommonNotify('Contact added Successfully', 'success')
      })
      .catch(err => {
        this.setState({ isLoading: false, buttonLoader: false })

        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Not able to add Contact'
        )
      })
  }

  addBlockList = async () => {
    let { selectedType, selectedData } = this.state

    const blockData = selectedData
    const blockType = selectedType

    const apiToken = await localStorage.getItem('access_token')
    const w_id = await localStorage.getItem('widget_id')

    const data = {
      block_reason: 'Blocked from Lead',
      widget_id: w_id
    }

    if (blockType == 'Email') {
      if (blockData.email === '' || blockData.email === null) {
        CommonNotify('Email is not correct.')
        return
      } else {
        data.email = blockData.email
      }
    } else if (blockType == 'Phone') {
      if (blockData.phone === '' || blockData.phone === null) {
        CommonNotify('Phone is not correct.')
        return
      } else {
        data.phone_number = blockData.phone
        data.phone_country = blockData.countryCode
        data.block_reason = 'Blocked From Lead'
      }
    } else {
      return
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
    axios
      .post(url, data)
      .then(res => {
        this.banModalView('', '', false)

        if (res.data.message[0] == 'Successfully') {
          CommonNotify('User Added to BlockList', 'success')
        }
      })
      .catch(error => {
        this.banModalView('', '', false)
        CommonNotify(error.response.data.errors[0], 'warning')
      })
  }

  handleSave = (e, name) => {
    this.handleItemClick(e, { name }, 3)
  }

  render() {
    const userMentionData = this.state.mentionUser.map(myUser => ({
      id: myUser._id,
      display: `${myUser.name.first} ${myUser.name.last}`
    }))

    const { interseted, lead_Id } = this.state
    const data = this.state.callData

    const logANote = (
      <Popup
        content="Create Note Activity"
        mouseEnterDelay={500}
        trigger={
          <div className="tags_set">
            <ButtonEvergreen
              closeOnSelect={true}
              iconBefore={EditIcon}
              className="activity-btn"
              title="Log a Note"
              loading={this.state.btnLoading}
              onClick={() =>
                this.showActivityUpdate(
                  'note',
                  'Add Logs',
                  'Start typing to leave a note'
                )
              }
            ></ButtonEvergreen>
          </div>
        }
      />
    )
    const addActivity = (
      <Popup
        content="Create Custom Activity"
        mouseEnterDelay={500}
        trigger={
          <div className="tags_set">
            <ButtonEvergreen
              iconBefore={AnnotationIcon}
              className="activity-btn"
              title="Add a Activity"
              loading={this.state.btnLoading}
              onClick={() =>
                this.showActivityUpdate('custom', 'Create Custom Activity')
              }
            ></ButtonEvergreen>
          </div>
        }
      />
    )
    return (
      <>
        {this.state.isLoading ? (
          <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
            <Loader />
          </Dimmer>
        ) : (
          <div className="lead_page">
            <style>
              {`
                    [evergreen-portal-container] > div > div {
                      position: absolute !important;
                    }
                  `}
            </style>
            <Modal
              className="local-number-modal"
              open={this.state.banModal}
              onClose={e => this.banModalView('', '', false)}
            >
              <Modal.Content>
                <div className="modal-content">
                  <p style={{ marginTop: 20 }}>
                    Are you sure you want to ban selected user ?
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <CommonButtons
                      onClick={() => this.banModalView('', '', false)}
                      type="button"
                      content="Cancel"
                      background="red"
                      style={{
                        borderRadius: 0,
                        backgroundColor: '#e0e1e2',
                        color: '#5a5a5a'
                      }}
                    />

                    <CommonButtons
                      onClick={this.addBlockList}
                      type="button"
                      content="Confirm"
                      background="blue"
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        borderRadius: 0,
                        backgroundColor: '#1071eb',
                        color: '#ffffff'
                      }}
                    />
                  </div>
                </div>
              </Modal.Content>
            </Modal>

            <Modal
              className="local-number-modal"
              open={this.state.deleteModal}
              onClose={e => this.deleteModalView(false)}
            >
              <Modal.Content>
                <div className="modal-content">
                  <p style={{ marginTop: 20 }}>
                    Are you sure you want to delete this lead ?
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <CommonButtons
                      onClick={() => this.deleteModalView(false)}
                      type="button"
                      content="Cancel"
                      background="red"
                      style={{
                        borderRadius: 0,
                        backgroundColor: '#e0e1e2',
                        color: '#5a5a5a'
                      }}
                    />

                    <CommonButtons
                      onClick={this.onDeleteLeadHandler}
                      type="button"
                      content="Confirm"
                      background="blue"
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        borderRadius: 0,
                        backgroundColor: '#1071eb',
                        color: '#ffffff'
                      }}
                    />
                  </div>
                </div>
              </Modal.Content>
            </Modal>

            <Modal
              className="local-number-modal"
              open={this.state.addContactModal}
              onClose={e => this.contactModalView(false)}
            >
              <Modal.Content>
                <div className="modal-content">
                  <p style={{ marginTop: 20 }}>
                    Are you sure you want to convert this lead as contact ?
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <CommonButtons
                      onClick={() => this.contactModalView(false)}
                      type="button"
                      content="Cancel"
                      background="red"
                      style={{
                        borderRadius: 0,
                        backgroundColor: '#e0e1e2',
                        color: '#5a5a5a'
                      }}
                    />

                    <CommonButtons
                      loading={this.state.buttonLoader}
                      onClick={this.addContactLeadHandler}
                      type="button"
                      content="Confirm"
                      background="blue"
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        borderRadius: 0,
                        backgroundColor: '#1071eb',
                        color: '#ffffff'
                      }}
                    />
                  </div>
                </div>
              </Modal.Content>
            </Modal>

            {this.state.cancelReject && (
              <RejectPopup
                id={this.props?.match?.params?.id}
                handleSave={this.handleSave}
                setRejectModal={() =>
                  this.setState({
                    cancelReject: false
                  })
                }
              />
            )}

            <div className="row main_wrapper">
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '95%',
                  paddingTop: '12px'
                }}
              >
                {data.previous_lead_id && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.handleOpenLead(data.previous_lead_id)}
                  >
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={leftArrow}
                    />
                    <h5 style={{ display: 'inline-block' }}>Prev Lead </h5>
                  </span>
                )}

                {data.next_lead_id && (
                  <span
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => this.handleOpenLead(data.next_lead_id)}
                  >
                    <h5 style={{ display: 'inline-block' }}>Next Lead </h5>
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '20px'
                      }}
                      src={rightArrow}
                    />
                  </span>
                )}
              </div>
              <div className="col-xl-4" style={{ marginTop: '25px' }}>
                <div className="live_call">
                  <p
                    className="live_call_icon"
                    style={{ backgroundColor: '#0071eb' }}
                  >
                    {' '}
                    <img src={Callset} />
                    <span>
                      {data.type == 'LEAVE_MESSAGE'
                        ? 'OFFLINE MESSAGE'
                        : data?.type?.replace('_', ' ')}
                    </span>
                  </p>
                  <p className="live_call_date">
                    {moment(data.createdAtTime, 'hh:mm:ss').format(
                      'hh:mm:ss A'
                    )}{' '}
                    , {data.createdAtDate}
                    <img
                      onClick={() => this.deleteModalView(true)}
                      src={deleteIcon}
                      className="delete-icon"
                      alt="delete"
                    />
                  </p>
                </div>
                <div className="dispaly_profile">
                  <div className="profile_boxes">
                    <div className="profile_left">
                      <div className="profile_images">
                        <img src={DummyProfileImage} />
                      </div>

                      <div className="profile_detail">
                        <p>
                          {data.customer_name &&
                          data.customer_name !== 'undefined'
                            ? data.customer_name
                            : 'Contact ' + lead_Id}

                          <a
                            style={{}}
                            className="mail_set col"
                            onClick={e =>
                              this.setState({
                                ...this.state,
                                openLead: true
                              })
                            }
                          >
                            <img src={editLead} />
                          </a>

                          {!data.contact ? (
                            <a
                              style={{}}
                              className="mail_set col"
                              onClick={e =>
                                this.setState({
                                  ...this.state,
                                  addContactModal: true
                                })
                              }
                            >
                              <img style={{ width: 22 }} src={convertContact} />
                            </a>
                          ) : null}
                        </p>
                      </div>
                    </div>
                    <p style={{ color: 'gray' }}>
                      {data.contact ? 'Contact' : 'Lead'}{' '}
                    </p>
                  </div>
                  <div className="dispaly_profile_detail Lead_wrapper">
                    {/* --------------------- Qualification ------------------ */}

                    <div className="form-group row">
                      <div className="col-md-4">
                        <p className="mb-0 text-color lead_title">
                          <Image src={checkIcon} className="lead-data-icon" />
                          <label className="mb-0">Email</label>
                        </p>
                      </div>
                      <div className="col-md-1">: </div>
                      <div className="col-md-7">
                        <span>
                          {data.email && data.email !== 'undefined'
                            ? data.email
                            : 'Unknown'}
                        </span>
                        {data.email && data.email !== 'undefined' ? (
                          <i
                            onClick={() =>
                              this.banModalView('Email', data, true)
                            }
                            style={{ marginLeft: 10, color: 'red' }}
                            className="fa fa-ban align-middle"
                            aria-hidden="true"
                          ></i>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <p className="mb-0 text-color lead_title">
                          <Image src={checkIcon} className="lead-data-icon" />
                          <label className="mb-0">Phone</label>
                        </p>
                      </div>
                      <div className="col-md-1">: </div>
                      <div className="col-md-7">
                        <span>
                          {data.phone_number ? data.phone_number : 'Unknown'}
                        </span>
                        {data.phone_number && (
                          <i
                            onClick={() =>
                              this.banModalView('Phone', data, true)
                            }
                            style={{ marginLeft: 10, color: 'red' }}
                            className="fa fa-ban"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <p className="mb-0 text-color lead_title">
                          <Image src={checkIcon} className="lead-data-icon" />
                          <label className="mb-0">Company Name</label>
                        </p>
                      </div>
                      <div className="col-md-1">: </div>
                      <div className="col-md-7">
                        <span>
                          {data.company_name ? data.company_name : ''}
                        </span>
                      </div>
                    </div>

                    {/* <div className="form-group row">
                      <div className="info-item-inner">
                        <div className="checkWrap">
                          <Image src={checkIcon} className="lead-data-icon" />
                        </div>
                        <p>Company Size</p>
                      </div>
                      <div>
                        <span>{data.employees ? data.employees : ''}</span>
                      </div>
                    </div> */}

                    {/* <div className="form-group row">
                      <div className="info-item-inner">
                        <div className="checkWrap">
                          <Image src={checkIcon} />
                        </div>
                        <p>Industry</p>
                      </div>
                      <div>
                        <span>{data.industry ? data.industry : ''}</span>
                      </div>
                    </div> */}

                    <div className="form-group row">
                      <div className="col-md-4">
                        <p className="mb-0 text-color lead_title">
                          <Image src={checkIcon} className="lead-data-icon" />
                          <label className="mb-0">Country</label>
                        </p>
                      </div>
                      <div className="col-md-1">: </div>
                      <div className="col-md-7">
                        <span>
                          {data.country
                            ? billingCountryList[
                                billingCountryList.findIndex(
                                  x => x.value === data.country
                                )
                              ].label
                            : ''}
                        </span>
                      </div>
                    </div>

                    <div className="display_button">
                      {data.phone_number && (
                        <div className="row">
                          <a
                            className="col"
                            onClick={() => {
                              setTimeout(() => {
                                this.setState({
                                  callBackModal: true
                                })
                              }, 200)
                            }}
                          >
                            <img
                              style={{ height: '30px', width: '30px' }}
                              src={callbackIcon}
                            />

                            {this.state?.callBackModal && (
                              <CallbackModal
                                isModalClose={() => {
                                  setTimeout(() => {
                                    this.setState({
                                      callBackModal: false
                                    })
                                  }, 400)
                                }}
                                isModalOpen={() => console.log(`open`)}
                                open={this.state?.callBackModal}
                                dropDownData={this.state.dropDownData || []}
                                leadData={data}
                                phone_numbber={data.phone_number}
                              />
                            )}
                          </a>
                          <a
                            className="col"
                            onClick={e =>
                              this.props.getDialer(data.phone_number)
                            }
                          >
                            <img
                              style={{ height: '30px', width: '30px' }}
                              src={smsNewIcon}
                            />
                          </a>

                          <a
                            className="col"
                            onClick={e =>
                              this.setState({
                                ...this.state,
                                open: true
                              })
                            }
                          >
                            <img
                              style={{ height: '30px', width: '30px' }}
                              src={callNewIcon}
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="good-fit-menu">
                  <div className="titleCall"></div>

                  <Label
                    style={{
                      fontWeight: 300,
                      color: 'black',
                      backgroundColor: '#ffffff',
                      borderColor: '#9a9393',
                      marginRight: 20
                    }}
                  >
                    Qualified?
                  </Label>
                  <Menu>
                    <Popup
                      content="Mark as Qualified"
                      trigger={
                        <Menu.Item
                          name="1"
                          active={interseted == '1'}
                          onClick={(e, name) =>
                            this.handleItemClick(e, { name }, 1)
                          }
                        >
                          <i className="fa fa-check"></i>
                        </Menu.Item>
                      }
                    />

                    <Popup
                      content="Not Reviewed"
                      trigger={
                        <Menu.Item
                          name="2"
                          active={interseted == '2'}
                          onClick={(e, name) =>
                            this.handleItemClick(e, { name }, 2)
                          }
                        >
                          <i className="fa fa-question"></i>
                        </Menu.Item>
                      }
                    />
                    <Popup
                      content="Mark as Not Qualified"
                      trigger={
                        <Menu.Item
                          name="3"
                          active={interseted == '3'}
                          onClick={(e, name) =>
                            this.setState({
                              cancelReject: true
                            })
                          }
                        >
                          <i className="fa fa-times"></i>
                        </Menu.Item>
                      }
                    />
                  </Menu>
                </div>

                {this.state.open && (
                  <SendMessageModal
                    isModalClose={this.isModalClose}
                    isModalOpen={this.isModalOpen}
                    open={this.state.open}
                    dropDownData={this.state.dropDownData}
                    leadData={data}
                    phone_numbber={data.phone_number}
                  />
                )}

                <CreateCallModal
                  isModalClose={this.isModalCallClose}
                  isModalOpen={this.isModalCallOpen}
                  open={this.state.openCall}
                  dropDownData={this.state.dropDownData}
                  leadData={data}
                  onCallRequest={onCallRequest}
                  leadId={this.state.lead_Id}
                />

                <UpdateLeadModal
                  isModalClose={this.isModalLeadClose}
                  isModalOpen={this.isModalLeadOpen}
                  open={this.state.openLead}
                  leadData={data}
                  onUpdateRequest={this.onUpdateRequest}
                  leadId={this.state.lead_Id}
                  interested={this.state.interestedData}
                  lead_interest={this.state.lead_interest}
                />

                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    paddingBottom: 10,
                    paddingTop: 10,
                    marginTop: 15,
                    display: 'flex'
                  }}
                >
                  <Dropdown
                    style={{ fontSize: 12, fontWeight: 200 }}
                    placeholder="Add Tags"
                    className="dropdown-wrapper Colaboration-dropdown"
                    multiple
                    defaultValue={this.state.tagsDataDefault}
                    options={this.state.tagsData}
                    onChange={this.changeTags}
                  />
                  <ButtonEvergreen
                    style={{ float: 'right' }}
                    title="Add tags"
                    loading={this.state.btnLoading}
                    onClick={this.onTagsSaveHandler}
                  ></ButtonEvergreen>
                </div>

                <div className="Calldata">
                  <Leadform data={this.state} />
                </div>

                <div className="leadInfo">
                  <LeadInfo data={this.state} />
                </div>

                <div className="Calldata">
                  <Calldata data={this.state} />
                </div>
                <LeadPageModal
                  show={this.state.show}
                  handleClose={this.handleClose}
                  data={
                    this.state.dataTag === 'tags'
                      ? this.Tags
                      : this.Collaborators
                  }
                />
              </div>
              <div className="col-xl-5" style={{ marginTop: '25px' }}>
                {/* Add good fit if need in feature */}
                <div className="lead_deatil_side">
                  <div className="title_wrapper">
                    <Popup
                      content="Collaboration"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EverGreenSelect
                            head="Share"
                            type="members"
                            title="Share"
                            closeOnSelect={true}
                            allOptions={this.state.collaboratesData}
                            defaultValues={this.state.defaultCollaborator}
                            onUpdateValues={this.onCollaboratorsHandler}
                          ></EverGreenSelect>
                        </div>
                      }
                    />

                    <Popup
                      content="Lead Score"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EvergreenSingleSelect
                            title="Lead Score"
                            closeOnSelect={true}
                            options={this.state.leadScore}
                            onUpdateValues={this.onScoreSaveHandler}
                            defaultOptions={this.state.leadScoreDefault}
                          ></EvergreenSingleSelect>
                        </div>
                      }
                    />
                    <Popup
                      content="Lead Owner"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EvergreenSingleSelect
                            title="Lead Owner"
                            closeOnSelect={true}
                            options={this.state.assignOption}
                            onUpdateValues={this.onChangeAssign}
                            defaultOptions={this.state.defaultOwner}
                          ></EvergreenSingleSelect>
                        </div>
                      }
                    />

                    <Popup
                      content="Lead Status"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EvergreenSingleSelect
                            closeOnSelect={true}
                            title="Lead Status"
                            options={statusOption}
                            onUpdateValues={this.setStatusLead}
                            defaultOptions={this.state.defaultStage}
                          ></EvergreenSingleSelect>
                        </div>
                      }
                    />

                    <Popup
                      content="Lead Stage"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EvergreenSingleSelect
                            closeOnSelect={true}
                            title="Lead Stage"
                            options={this.state.stageData}
                            onUpdateValues={this.onStageSaveHandler}
                            defaultOptions={this.state.leadStageData}
                          ></EvergreenSingleSelect>
                        </div>
                      }
                    />

                    <Popup
                      content="Lead Interest"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <EvergreenSingleSelect
                            closeOnSelect={true}
                            title="Lead Interest"
                            options={this.state.interestData}
                            onUpdateValues={this.onUpdateInterest}
                            defaultOptions={this.state.lead_interest}
                          ></EvergreenSingleSelect>
                        </div>
                      }
                    />
                  </div>
                </div>{' '}
                <div className="lead_deatil_side">
                  <div className="title_wrapper">
                    {/* <Popup
                      content="Create Note Activity"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <ButtonEvergreen
                            closeOnSelect={true}
                            iconBefore={EditIcon}
                            className="activity-btn"
                            title="Log a Note"
                            loading={this.state.btnLoading}
                            onClick={() =>
                              this.showActivityUpdate(
                                'note',
                                'Add Logs',
                                'Start typing to leave a note'
                              )
                            }
                          ></ButtonEvergreen>
                        </div>
                      }
                    /> */}

                    {/* <Popup
                      content="Create Phone Activity"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                           <ButtonEvergreen                                  
                                  iconBefore={PhoneIcon}
                                  className="activity-btn"
                                  title="Phone"
                                  loading={this.state.btnLoading}
                                  onClick={()  => this.showActivityUpdate('phone', 'Create Phone Activity') }
                                ></ButtonEvergreen>
                        </div>
                      }
                    />   */}

                    {/* <Popup
                      content="Create Email Activity"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <ButtonEvergreen                                  
                                   iconBefore={EnvelopeIcon}
                                  className="activity-btn"
                                  title="Email"
                                  loading={this.state.btnLoading}
                                  onClick={()  => this.showActivityUpdate('email', 'Create Email activity') }
                                ></ButtonEvergreen>
                        </div>
                      }
                    />      */}

                    {/* <Popup
                      content="Create Custom Activity"
                      mouseEnterDelay={500}
                      trigger={
                        <div className="tags_set">
                          <ButtonEvergreen
                            iconBefore={AnnotationIcon}
                            className="activity-btn"
                            title="Add a Activity"
                            loading={this.state.btnLoading}
                            onClick={() =>
                              this.showActivityUpdate(
                                'custom',
                                'Create Custom Activity'
                              )
                            }
                          ></ButtonEvergreen>
                        </div>
                      }
                    /> */}
                  </div>
                </div>{' '}
                <div
                  className={` ${
                    this.state.commentBoxOpen
                      ? 'streamline active'
                      : 'streamline'
                  } `}
                  style={{ marginBottom: 20 }}
                >
                  <div
                    className="sl-item"
                    style={{ borderColor: 'rgb(87, 138, 103)' }}
                  >
                    {/* {this.state.showAddNotes ? (
                      <div>
                        <div className="sl-icon"></div>
                        <div
                          className="sl-content box px-3 py-2"
                          style={{
                            backgroundColor: 'white',
                            marginTop: 20,
                            marginBottom: 20
                          }}
                        >
                          <MentionsInput
                            value={this.state.value}
                            onChange={this.handleChange}
                            placeholder="Write a note..."
                            className="mentions"
                          >
                            <Mention
                              type="user"
                              trigger="@"
                              data={userMentionData}
                              className="mentions__mention"
                            />
                          </MentionsInput>

                          <div className="sl-foote row">
                            <div className="sl-footer-left col-6 d-flex align-items-center"></div>
                            <div className="sl-footer-right col-6 d-flex justify-content-end align-items-center">
                              {this.state.noteUpdate ? (
                                <>
                                  <ButtonEvergreen
                                    title="Update"
                                    loading={this.state.btnLoading}
                                    onClick={this.editNotes}
                                  ></ButtonEvergreen>{' '}
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        noteUpdate: false,
                                        value: ''
                                      })
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </>
                              ) : (
                                <ButtonEvergreen
                                  title="POST"
                                  loading={this.state.btnLoading}
                                  onClick={this.createNotes}
                                ></ButtonEvergreen>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null} */}
                  </div>

                  {/* {this.state.getNotes.length > 0 && this.state.showAddNotes ? (
                    <div className="activities-wrapper">
                      {this.state.getNotes &&
                        this.state.getNotes?.map((data, index) => {
                          return (
                            <div className="activities-main">
                              <style>
                                {`.activities-wrapper .item-green {
                                    border-left-style: none !important;
                                }
                                .activities-wrapper .sl-content .ui.button.circular {
                                  padding: .78571429em .78571429em .78571429em !important;
                                }
                                `}
                              </style>
                              <div className={`sl-activities-item item-green`}>
                                <div className="sl-icon">
                                  <i className="fas fa-sticky-note"></i>
                                </div>
                                <div
                                  className="sl-content box px-3 py-2"
                                  style={{
                                    backgroundColor: 'white',
                                    marginTop: 6,
                                    minHeight: '80px'
                                  }}
                                >
                                  <div className="notes-wrapper">
                                    <p style={{ fontSize: 15, color: 'grey' }}>
                                      {' '}
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          color: 'black'
                                        }}
                                      >
                                        Note{' '}
                                      </span>{' '}
                                    </p>

                                    <div>
                                      <p
                                        style={{ fontSize: 14, color: 'gray' }}
                                      >
                                        {moment
                                          .utc(data[1].date)
                                          .local()
                                          .startOf('seconds')
                                          .fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      minHeight: '50px',
                                      position: 'relative'
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: 400,
                                        fontSize: 14,
                                        marginTop: -10
                                      }}
                                    >
                                      &nbsp; <span>{data[1].note}</span>
                                    </p>
                                    <Button
                                      circular
                                      icon="edit"
                                      onClick={() =>
                                        this.setState({
                                          noteUpdate: true,
                                          value: data[1].note,
                                          indexNote: data[0]
                                        })
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      position: 'relative',
                                      height: '20px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: 'absolute',
                                        right: 0,
                                        color: 'gray'
                                      }}
                                    >
                                      <span>by</span> {data[1].userName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  ) : (
                    ''
                  )} */}
                </div>
                {this.state.showActivity ? (
                  <LeadActivities
                    addActivityBtn={addActivity}
                    logANoteBtn={logANote}
                    activityUpdated={this.state.activityUpdated}
                    closeAddActivity={() =>
                      this.setState({ showAddActivity: false })
                    }
                    showActivityUpdateNew={this.showActivityUpdateNew}
                    showAddActivity={this.state.showAddActivity}
                    showActivityType={this.state.activityType}
                    showActivityTitle={this.state.activityTitle}
                    reloadActivity={this.getLeadActivities}
                    addActivity={_createActivity}
                    datas={this.state.leadActivities}
                    leadId={this.state.lead_Id}
                  />
                ) : null}
              </div>
              <div className="col-xl-3" style={{ marginTop: '25px' }}>
                <div className="CallLogs">
                  <CallLogs data={this.state} />
                </div>
                {data.contact ? (
                  <div className="visitData">
                    <ContactData
                      data={this.state}
                      refresh={this.intitialFunctionCall}
                    />
                  </div>
                ) : null}
                <div className="Calldata">
                  <AgentData data={this.state} />
                </div>

                <div className="insights">
                  <Insights data={this?.state?.leadInfo} />
                </div>

                <div className="visitData">
                  <VisitData data={this.state} />
                </div>

                <div className="CallLogs">
                  <UtmData data={this.state} />
                </div>

                <div className="CallLogs">
                  <RecentVisits
                    history={this.state.recentPageHistory}
                    data={this.state}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    dialerStatus: state.dialer.currentStatu,
    callForwadingSettings: state.settings.callForwadingSettings
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, { getDialer, getCallForwadingSettings })
)(CallDashboard)
