import React, { Component, Fragment } from 'react'
import { Table, Image, Modal, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'

import CommonInput from './CommonInput'
import CommonGroupButton from './CommonGroupButton'
import CommonButtons from './CommonButtons'

import starterIcon from '../assets/images/startericon.svg'
import proIcon from '../assets/images/proicon.svg'
import advanceIcon from '../assets/images/advanceicon.svg'

import checked from '../assets/images/checked.svg'
import americanExpress from '../assets/images/americanexpress.svg'
import visa from '../assets/images/visa.svg'
import masterCard from '../assets/images/mastercard.svg'
import { cancelSubscription } from '../config/subscription'
import Toggle from '../common/CommonToggle'
import HelpIcon from '@mui/icons-material/Help'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import axios from 'axios'
import { CommonNotify } from './CommonNotify'
import CommonAddCardModal from './CommonAddCardModal'
import ConfirmUpgradePlanModal from './ConfirmUpgradePlanModal'
import moment from 'moment'
import { connect } from 'react-redux'
import { plansAction } from '../actions/home'
import { getSubscription } from '../actions/home'
import { List, ListItem } from '@mui/material'

class CommonSubscriptionModal extends Component {
  state = {
    anumPlan: false,
    isModalOpen: false,
    globalPlans: [],
    promoCode: '',
    isCancelModalOpen: false,
    cardSummary: false,
    allPlans: [],
    width: window.innerWidth,
    activePlans: null,
    select: {},
    selectedPlan: { activeLabel: '', activePrice: 0, actualPrice: 0 },
    payCard: [],
    updateCard: false,
    payCardDropdown: [],

    dataModal: {
      dataPlan: {
        planName: 'Pro',
        planPrice: '$119'
      },
      addCredits: '',
      promoCode: '',
      creditCardDetails: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      },
      creditCardDetailsErrors: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      }
    },
    subscriptionData: {
      plan_id: 8,
      apply_now: 1,
      period: 'Monthly',
      downgrading_reason: '',
      selected_plan_price: {},
      current_plan_price: 35,
      coupon: '',
      cardId: 0
    },
    newCard: false,
    applyCouponLoader: false,
    addPaymentLoader: false,
    addCardModalOpen: false,
    confirmOnOpen: false,
    selectedIndex: null,
    openHelp: false,
    current: ''
  }
  applyNowToggle = {
    callTitle: 'applyNow',
    callDesc: 'applyNow',
    callId: 'applyNow',
    callref: 'applyNow'
  }
  // fetch plan details

  setPlanData = plans => {
    let { currentPlan } = this.props

    let images = [starterIcon, proIcon, advanceIcon, advanceIcon, advanceIcon]
    if (plans) {
      plans.forEach((item, index, theArray) => {
        theArray[index].icon = images[0]
        theArray[index].yearly_total = 0
        item.yearly_price = item.yearly_price ? item.yearly_price : 0

        if (item.yearly_price) {
          theArray[index].yearly_total = item.yearly_price * 12
        }
        if (currentPlan) {
          if (item.name.toLowerCase() == currentPlan.toLowerCase()) {
            if (
              this.props.subScription?.status === 'active' ||
              this.props.subScription?.status === 'in_trial'
            ) {
              theArray[index].active = true
            } else {
              theArray[index].active = false
            }
          } else {
            theArray[index].active = false
          }
        }
      })
      const getActivePlaneData = plans.find(element => element.active) || {}
      this.setState({ activePlans: getActivePlaneData })
      this.setState({
        allPlans: plans.filter(
          plan => !plan.is_yearly && plan?.type === 'LimePhone'
        ),
        globalPlans: plans
      })
    }
  }

  componentDidMount = async () => {
    // const role_name = await localStorage.getItem('role_name')
    // if (role_name === 'Admin') {
    //    this.props.fixed && this.fetchPlans()
    //    this.fetchCard()
    // }
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.currentPlan, prevProps.currentPlan)) {
      // this.fetchPlans()
      const subscriptionData = {
        plan_id: 8,
        period: 'Monthly',
        downgrading_reason: '',
        selected_plan_price: {},
        current_plan_price: 35,
        coupon: '',
        cardId: 0
      }

      this.setState({ cardSummary: false, subscriptionData: subscriptionData })
    }
    if (!_.isEqual(this.props.plansData, prevProps.plansData)) {
      this.setPlanData(this.props.plansData)
    }
    if (!_.isEqual(this.props.open, prevProps.open)) {
      const role_name = localStorage.getItem('role_name')
      this.fetchPlans()
      if (this.props.open && role_name === 'Admin') {
        this.fetchCard()
      }
    }
  }

  fetchCard = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios
      .get(url)
      .then(res => {
        if (res) {
          if (res.data.data.length === 1) {
            const { subscriptionData } = this.state
            subscriptionData['cardId'] = res.data.data[0].id
            this.setState({ subscriptionData })
            this.setState({ updateCard: true })
            this.setState({ updateCardInfo: res.data.data[0] })
          }
          const updatePayCard = res.data.data.map(item => {
            return {
              key: `${item.card.expiry_month} / ${item.card.expiry_month}`,
              text: `**** ${item.card.last_four_digits}`,
              value: item.id
            }
          })
          this.setState({
            payCard: res.data.data,
            payCardDropdown: updatePayCard
          })
        }
      })
      .catch(function(error) {})
  }

  applyCoupon = () => {}

  fetchPlans = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/plans`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setPlanData(res.data.data)
          this.props.plansAction(res.data.data)
        }
      })
      .catch(function(error) {
        if (error.response) {
          //alert('Invalid Coupon Code')
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
  }

  executeCoupanCode = () => {
    this.setState({ applyCouponLoader: true })
    if (!this.state.promoCode) {
      CommonNotify('Please enter Code', 'warning')
      this.setState({ applyCouponLoader: false })
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/check-coupon`
    let data = {
      price: this.state.selectedPlan.activePrice,
      coupon: this.state.promoCode
    }
    axios
      .post(url, data)
      .then(res => {
        if (res.data) {
          let { selectedPlan, subscriptionData } = this.state
          selectedPlan.actualPrice = res.data.data
          this.setState({ selectedPlan: selectedPlan })
          subscriptionData['coupon'] = res.data.data
          this.setState({ subscriptionData })
          this.setState({ applyCouponLoader: false })
        }
      })
      .catch(error => {
        if (error.response) {
          this.setState({ applyCouponLoader: false })
          CommonNotify('Invalid Coupon Code')
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
  }

  handleGroupBtnData = (toggleBtn, id) => {
    let { selectedPlan, subscriptionData } = this.state

    if (toggleBtn == 'Monthly') {
      this.setState({
        allPlans: this.state.globalPlans.filter(
          plan => !plan.is_yearly && plan?.type === 'LimePhone'
        )
      })
      selectedPlan.activePrice = parseInt(
        selectedPlan.price ? selectedPlan.price : 0
      )
      selectedPlan.actualPrice = parseInt(
        selectedPlan.price ? selectedPlan.price : 0
      )
      selectedPlan.activeLabel = 'Monthly Charge'
      subscriptionData['period'] = toggleBtn
      this.setState({ subscriptionData, anumPlan: false })
    } else {
      this.setState({
        allPlans: this.state.globalPlans.filter(
          plan => plan.is_yearly && plan?.type === 'LimePhone'
        )
      })
      selectedPlan.activePrice = parseInt(
        selectedPlan.price ? selectedPlan.price : 0
      )
      selectedPlan.actualPrice = parseInt(
        selectedPlan.price ? selectedPlan.price : 0
      )
      selectedPlan.activeLabel = 'Annual Charge'
      subscriptionData['period'] = toggleBtn
      this.setState({ subscriptionData, anumPlan: true })
    }

    this.setState({ selectedPlan: selectedPlan })
  }

  handleData = (toggleBtn, next) => {
    let { selectedPlan, subscriptionData } = this.state

    if (toggleBtn == 'Monthly') {
      this.setState({
        allPlans: this.state.globalPlans?.filter(
          plan => !plan.is_yearly && plan?.type === this.state.selectedPlanType
        )
      })
      selectedPlan.activePrice = parseInt(next.price ? next.price : 0)
      selectedPlan.actualPrice = parseInt(next.price ? next.price : 0)
      selectedPlan.activeLabel = 'Monthly Charge'
      subscriptionData['period'] = toggleBtn
      this.setState({ subscriptionData, anumPlan: false })
    } else {
      this.setState({
        allPlans: this.state.globalPlans?.filter(
          plan => plan.is_yearly && plan?.type === this.state.selectedPlanType
        )
      })
      selectedPlan.activePrice = parseInt(next.price ? next.price : 0)
      selectedPlan.actualPrice = parseInt(next.price ? next.price : 0)
      selectedPlan.activeLabel = 'Annual Charge'
      subscriptionData['period'] = toggleBtn
      this.setState({ subscriptionData, anumPlan: true })
    }

    this.setState({ selectedPlan: selectedPlan })
  }

  handleDataModal = (e, index, plans) => {
    let { dataModal, subscriptionData, cardSummary } = this.state
    const newPlanName = e.target.parentNode.querySelector('.plan-title')
    const newPlanPrice = e.target.parentNode.querySelector('.plan-price')
    const planStatus = e.target.parentNode.querySelector('.plan-status')
    const allPlanStatus = [...document.querySelectorAll('.plan-status')] || []

    subscriptionData['selected_plan_price'] = {
      price: plans.price ? plans.price : 0,
      yearly_price: plans.yearly_price ? plans.yearly_price : 0
    }

    subscriptionData['plan_id'] = plans.id
    this.setState({
      subscriptionData,
      cardSummary: false,
      selectedIndex: index
    })

    if (planStatus.innerHTML !== 'Current Plan') {
      dataModal.dataPlan = {
        planName: newPlanName.innerHTML,
        planPrice: newPlanPrice.innerHTML
      }

      let { allPlans } = this.state
      let newPlans = allPlans.map(el => ({ ...el, active: false }))
      let selectedPlan = allPlans[index]

      selectedPlan.activePrice = plans.price
      selectedPlan.activeLabel = 'Monthly charge'

      if (this.state.anumPlan) {
        selectedPlan.activePrice = plans.yearly_total
        selectedPlan.activeLabel = 'Annual charge'
      }

      selectedPlan.actualPrice = selectedPlan.activePrice
      selectedPlan.selected = true

      newPlans[index] = selectedPlan
      this.setState({
        selectedPlan: selectedPlan,
        allPlans: newPlans?.filter(plan => plan?.type === 'LimePhone')
      })
      this.setState({ dataModal })
    }
  }

  handleDataInput = e => {
    const { name, value } = e.target
    const { dataModal } = this.state
    dataModal[name] = value

    this.setState({ dataModal })
  }

  handleCreditCardInfo = e => {
    const { name, value } = e.target
    const { dataModal } = this.state
    dataModal.creditCardDetails[name] = value

    this.setState({ dataModal })
    const ERROR_CODE = {
      cardName: 'Card Name',
      cardNumber: 'Card Number',
      validMonth: 'Month',
      validYear: 'Year',
      cvv: 'CVV'
    }

    if (!value) {
      dataModal.creditCardDetailsErrors[
        name
      ] = `${ERROR_CODE[name]} is required`
      this.setState({ dataModal })
      return
    } else {
      dataModal.creditCardDetailsErrors[name] = ''
      this.setState({ dataModal })
    }

    if (name === 'cardNumber') {
      if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validMonth') {
      if (value.toString().length > 2) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 2 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validYear') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'cvv') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }
  }

  handleModal = () => {
    let { isModalOpen, cardSummary } = this.state

    isModalOpen = !isModalOpen
    cardSummary = false
    this.setState({ isModalOpen, cardSummary })
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleCancelModal = () => {
    let { isCancelModalOpen } = this.state

    isCancelModalOpen = !isCancelModalOpen

    this.setState({ isCancelModalOpen })
  }

  handleCloseCancelModal = () => this.setState({ isCancelModalOpen: false })

  handleCreditCard = () => {
    this.setState({ cardSummary: true })
  }

  checkSubscription = () => {
    if (_.isEmpty(this.state.subscriptionData.selected_plan_price)) {
      CommonNotify('Please Select Plan First', 'warning')
      return
    }
    this.setState({ confirmOnOpen: true })
  }

  onCancel = () => {
    cancelSubscription()
      .then(res => {
        this.setState({ isCancelModalOpen: false })
      })
      .catch(err => console.error())
  }

  animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) return

    var start = new Date().getTime(),
      timer = setInterval(function() {
        var step = Math.min(1, (new Date().getTime() - start) / time)

        if (prop) {
          elem[style] = from + step * (to - from) + unit
        } else {
          elem.style[style] = from + step * (to - from) + unit
        }

        if (step === 1) {
          clearInterval(timer)
        }
      }, 25)
    if (prop) {
      elem[style] = from + unit
    } else {
      elem.style[style] = from + unit
    }
  }

  onChangeCheckBox = (e, index, checked, data) => {
    if (index.checked) {
      const { subscriptionData } = this.state
      subscriptionData['cardId'] = data.id
      this.setState({ subscriptionData })
    } else {
      const { subscriptionData } = this.state
      subscriptionData['cardId'] = ''
      this.setState({ subscriptionData })
    }
  }

  onAddCreditCard = () => {
    if (
      !this.state.dataModal.creditCardDetails.cardNumber.match(/^[-_ 0-9]+$/)
    ) {
      CommonNotify('Invalid Card No', 'warning')
      return
    }

    this.setState({ addCreditCardLoader: true })

    if (
      !this.state.dataModal.creditCardDetails.cardName ||
      !this.state.dataModal.creditCardDetails.cardNumber ||
      !this.state.dataModal.creditCardDetails.validMonth ||
      !this.state.dataModal.creditCardDetails.validYear ||
      !this.state.dataModal.creditCardDetails.cvv
    ) {
      CommonNotify('Please add all felids', 'warning')
      this.setState({ addCreditCardLoader: false })
      return
    }
    const date = new Date()
    const newYear = moment(date).format('yyyy')
    if (this.state.dataModal.creditCardDetails.validMonth > 12) {
      this.setState({ addCreditCardLoader: false })
      CommonNotify('Please enter valid expire date', 'warning')
      return
    } else {
      if (this.state.dataModal.creditCardDetails.validYear < newYear) {
        this.setState({ addCreditCardLoader: false })
        CommonNotify(
          'Expiry date should not be lesser then current date',
          'warning'
        )
        return
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`

    const data = {
      name: this.state.dataModal.creditCardDetails.cardName,
      card_number: this.state.dataModal.creditCardDetails.cardNumber,
      expiry: `${this.state.dataModal.creditCardDetails.validMonth}/${this.state.dataModal.creditCardDetails.validYear}`,
      cvv: this.state.dataModal.creditCardDetails.cvv
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState({ addCreditCardLoader: false, newCard: false })
        CommonNotify(
          `Card successfully ${this.state.updateCard ? 'updated' : 'created'}`,
          'success'
        )
        this.setState({ addCardModalOpen: false })
        this.fetchCard()
      })
      .catch(err => {
        const errors = { ...err }
        this.setState({ addCreditCardLoader: false })

        this.setState({ errorMsg: '' })
        if (errors.response.data.errors.length) {
          //CommonNotify(errors.response.data.errors[0])
          const errorMsg = errors.response.data.errors[0]
          this.setState({ errorMsg })
        } else {
          this.setState({ errorMsg: 'Cant create payment methed.' })
          //CommonNotify("Cant create payment methed.")
        }
      })
  }

  addPayment = () => {
    // if no payment method
    if (!this.state.payCardDropdown.length) {
      this.onOpenAddCardModal()
      return
    }

    this.onCloseUpgradePlanModal()

    this.setState({ addPaymentLoader: true })
    if (!this.state.subscriptionData.cardId) {
      CommonNotify('Please select payment method', 'warning')
      this.setState({ addPaymentLoader: false })
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/update-primary-payment-method`
    const data = {
      payment_id: this.state.subscriptionData.cardId,
      plan_price:
        this.state.subscriptionData.period === 'Monthly'
          ? this.state.subscriptionData.selected_plan_price.price
          : this.state.subscriptionData.selected_plan_price.yearly_price * 12
    }
    axios
      .post(url, data)
      .then(res => {
        this.addNewSubscription()
      })
      .catch(err => {
        this.setState({ addPaymentLoader: false })
      })
  }

  addNewSubscription = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/update-subscription-plan`
    let v1 = 1
    if (this.state.subscriptionData.selected_plan_price.price === 0) {
      v1 = 0
    }

    const data = {
      plan_id: this.state.subscriptionData.plan_id,
      apply_now: v1,
      period:
        this.state.subscriptionData.period === 'Monthly' ? 'month' : 'year',
      downgrading_reason: '',
      selected_plan_price:
        this.state.subscriptionData.period === 'Monthly'
          ? this.state.subscriptionData.selected_plan_price.price
          : this.state.subscriptionData.selected_plan_price.yearly_price,
      coupon: '',
      current_plan_price: 0
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState({ addPaymentLoader: false })

        CommonNotify('Successfully', 'success')
        this.props.getSubscription && this.props.getSubscription()
        this.props.onClose && this.props.onClose()
      })
      .catch(err => {
        this.setState({ addPaymentLoader: false })
        const errors = { ...err }
        if (errors?.response?.data?.errors) {
          CommonNotify(errors?.response?.data?.errors?.[0])
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  handleToggleData = toggleData => {
    const dataToggled = toggleData ? 1 : 0
    const { subscriptionData } = this.state
    subscriptionData['apply_now'] = dataToggled
    this.setState({ subscriptionData })
  }

  onChangePaymentDropDown = (e, data) => {
    const { subscriptionData } = this.state
    subscriptionData['cardId'] = data.value
    this.setState({ subscriptionData })
  }

  onOpenAddCardModal = () => {
    this.setState({ addCardModalOpen: true })
  }

  onCloseAddCardModal = () => {
    this.setState({ addCardModalOpen: false })
  }

  onCloseUpgradePlanModal = () => {
    this.setState({ confirmOnOpen: false })
  }

  render() {
    const {
      isModalOpen,
      isCancelModalOpen,
      dataModal,
      cardSummary,
      width
    } = this.state
    const isMobile = width <= 768
    const { dataTable, currentPlan } = this.props
    const {
      cardName,
      cardNumber,
      validMonth,
      validYear,
      cvv
    } = this.state.dataModal.creditCardDetailsErrors
    const status =
      this.props.dataTable?.tableContentData?.[0]?.status ||
      localStorage?.getItem('account_status')
    const accountStatus = localStorage?.getItem('account')
    const active = this.state.allPlans?.filter(plan => plan?.active)
    const planName = localStorage?.getItem('plan_name')
    return (
      <Fragment>
        <Modal
          closeOnEscape={this.props.fixed === true ? true : false}
          closeOnDimmerClick={this.props.fixed === true ? true : false}
          className="subscription-modal"
          open={this.props.open}
          onClose={
            status !== 'trial_expired' &&
            accountStatus !== 3 &&
            this.props.onClose
          }
        >
          <Modal.Header>
            <p className="modal-header">
              {accountStatus === 3
                ? 'Your account has been cancelled. Please pick a plan to activate your account'
                : status === 'trial_expired'
                ? 'Your trial has expired. Please pick a plan to activate your account'
                : status === 'expired' || status === 'cancelled'
                ? 'Your active plan has expired. Please pick a plan to activate your account'
                : status === 'in_trial'
                ? `You are currently on the trial plan ${active?.name ||
                    planName} which expires in few Days`
                : status === 'trial_cancelled'
                ? 'Your account has been cancelled. Please pick a plan to activate your account'
                : 'Choose  your plan'}
            </p>
            {status === 'trial_expired' ? (
              <HelpIcon
                onClick={() =>
                  this.setState({ openHelp: !this.state?.openHelp })
                }
              />
            ) : (
              <i
                onClick={this.props.onClose}
                className="fa fa-times"
                aria-hidden="true"
              ></i>
            )}
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="flex-1">
              <div className="summary-tab-holder">
                <CommonGroupButton
                  title=""
                  identity="addForwardNumber"
                  leftBtn="Monthly"
                  rightBtn="Annual"
                  groupStyle="summary-toggle w-100"
                  handleGroupBtnData={e => this.handleGroupBtnData(e)}
                />
              </div>
              <div className="plans">
                <div className="plan-list">
                  {this.state.allPlans?.map((plan, index) => (
                    <>
                      {plan.price ? (
                        <>
                          <div
                            className="plan-item"
                            key="plan-item"
                            style={
                              plan.active
                                ? {
                                    minHeight: '424px',
                                    padding: '20px 20px 40px 20px',
                                    marginRight: '10px',
                                    marginBottom: '0',
                                    border: '1px solid #fff',
                                    boxShadow: '0 0 10px #0071eb'
                                  }
                                : {
                                    minHeight: '424px',
                                    padding: '20px 20px 40px 20px',
                                    marginRight: '10px',
                                    marginBottom: '0'
                                  }
                            }
                          >
                            {/* {!plan.active && (
                            <div
                              className="event-handler"
                              onClick={e =>
                                this.handleDataModal(e, index, plan)
                              }
                              disable={plan.active ? true : false}
                            ></div>
                          )} */}

                            {/* <Image src={plan.icon} size="small" /> */}
                            <p
                              className="plan-title"
                              style={{
                                fontWeight: 'bolder',
                                fontSize: '1rem',
                                textAlign: 'center',
                                whiteSpace: 'break-spaces',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%'
                              }}
                            >
                              {plan.name}
                            </p>
                            <p
                              className="plan-price"
                              style={{
                                fontSize: '2rem',
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: '#0071eb',
                                padding: '30px',
                                width: '100%',
                                boxShadow: '0px 15px 10px -15px #111'
                              }}
                            >
                              ${plan.price}
                              {plan.is_yearly ? '/year' : '/month'}
                            </p>
                            <List
                              sx={{
                                listStyleType: 'disc',
                                listStylePosition: 'inside',
                                padding: '20px 0',
                                borderWidth: '1px 0 1px 0',
                                borderStyle: 'solid',
                                borderColor: '#000'
                              }}
                            >
                              <ListItem
                                sx={{ display: 'list-item', padding: 0 }}
                              >
                                <span
                                  className="plan-desc"
                                  style={{
                                    fontSize: '0.85rem',
                                    color: '#262B3F',
                                    textAlign: 'center'
                                  }}
                                >
                                  USA Virtual Number Included
                                  {/* {plan.is_virtual ? ' Included' : ' Not included'} */}
                                </span>
                              </ListItem>

                              {/* <ListItem sx={{ display: 'list-item',padding:0 }}>
                              <span
                                className="plan-desc"
                                style={{
                                  fontSize: '0.85rem',
                                  color: '#262B3F',
                                  textAlign: 'center'
                                }}
                              >
                                Leads - {plan.max_leads}
                              </span>
                              </ListItem>   */}
                              <ListItem
                                sx={{ display: 'list-item', padding: 0 }}
                              >
                                <span
                                  className="plan-desc"
                                  style={{
                                    fontSize: '0.85rem',
                                    color: '#262B3F',
                                    textAlign: 'center'
                                  }}
                                >
                                  Users - {plan.max_users}
                                </span>
                              </ListItem>
                            </List>
                            {/* <div
                            style={{
                              borderBottom: 'solid 1px #E6E8EE',
                              padding: '12px 0',
                              width: '90%',
                              margin: '0 auto'
                            }}
                          >
                            <p
                              className="plan-desc"
                              style={{
                                textAlign: 'center',
                                fontSize: '0.75rem',
                                color: '#262B3F'
                              }}
                            >
                              Up to {plan.max_sms} SMS per month
                            </p>
                          </div> */}

                            {/* <p className="plan-subdesc">+35 leads for $29</p> */}

                            {plan?.active ? (
                              <p
                                className="plan-status"
                                style={{
                                  backgroundColor: '#009FAD',
                                  display: 'inline',
                                  fontSize: '1rem',
                                  whiteSpace: 'nowrap',
                                  fontWeight: '800',
                                  width: '160px',
                                  textAlign: 'center'
                                }}
                                onClick={e => {
                                  this.setState({
                                    ...this.state,
                                    current: plan?.name,
                                    select: { ...plan }
                                  })
                                  this.handleData(
                                    this.state.subscriptionData?.period,
                                    plan
                                  )
                                  this.handleDataModal(e, index, plan)
                                }}
                              >
                                Current Plan
                              </p>
                            ) : plan?.name === this.state.current ? (
                              <p
                                className="plan-status"
                                style={{
                                  backgroundColor: '#cc00e0',
                                  display: 'inline',
                                  fontSize: '1rem',
                                  whiteSpace: 'nowrap',
                                  fontWeight: '800',
                                  width: '160px',
                                  textAlign: 'center'
                                }}
                              >
                                Selected Plan
                              </p>
                            ) : (
                              <p
                                className="plan-status plan-selected"
                                style={{
                                  backgroundColor: '#0071eb',
                                  fontSize: '1rem',
                                  whiteSpace: 'nowrap',
                                  fontWeight: '800',
                                  width: '160px',
                                  textAlign: 'center'
                                }}
                                onClick={e => {
                                  this.setState({
                                    ...this.state,
                                    current: plan?.name,
                                    select: { ...plan }
                                  })
                                  this.handleData(
                                    this.state.subscriptionData?.period,
                                    plan
                                  )
                                  this.handleDataModal(e, index, plan)
                                }}
                              >
                                Select Plan
                              </p>
                            )}
                            {/* {plan.active === false && (
                            <p className="plan-status">Subscribe to</p>
                          )} */}
                          </div>
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ul>
                  <li>
                    Please disable VPN before updating subscriptions as orders
                    might be rejected.
                  </li>
                  <li>
                    Use this alternate gateway if you are unable to update
                    subscription.
                  </li>
                </ul>
              </div>
            </div>

            <div className="holder-small-content">
              {!cardSummary && (
                <div className="summary">
                  <p className="summary-title mb-3">Plan Details</p>
                  <div className="summary-plan">
                    <p style={{ fontSize: '18px' }}>Plan</p>
                    <span className="center-line" />
                    <p style={{ fontSize: '18px' }} className="gray-text">
                      {this.state.select.name}
                    </p>
                  </div>
                  <div className="summary-plan">
                    <p style={{ fontSize: '18px' }}>Users</p>
                    <span className="center-line" />
                    <p style={{ fontSize: '18px' }}>
                      {this.state.select.max_users}
                    </p>
                  </div>
                  <div className="summary-code mb-4">
                    <p style={{ fontSize: '18px' }}>
                      <i className="fas fa-question-circle mr-2" />
                      Have a promotional Code?
                    </p>
                    <CommonInput
                      style={{ border: '#F5F5F5' }}
                      onChange={e =>
                        this.setState({ promoCode: e.target.value })
                      }
                      name="promoCode"
                      placeholder="Enter your Code here"
                    />
                    <CommonButtons
                      onClick={this.executeCoupanCode}
                      content="Apply"
                      style={{ backgroundColor: '#0071eb', color: 'white' }}
                      loading={this.state.applyCouponLoader}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <p className="summary-title mb-3">Summary</p>
                    </div>
                    <div className="row align-items-center justify-content-between mx-0 mb-4">
                      <span style={{ fontSize: '18px' }}>
                        {this.state.selectedPlan.activeLabel ||
                          'Monthly Charge'}
                      </span>

                      <h6 style={{ fontSize: '18px' }}>
                        ${this.state.selectedPlan.activePrice}
                      </h6>
                    </div>
                    <div className="row align-items-center justify-content-between mx-0 mb-4">
                      <h6 style={{ fontSize: '18px' }}>Due Today</h6>
                      <h6 style={{ fontSize: '18px' }}>
                        $ {this.state.selectedPlan.actualPrice} USD
                      </h6>
                    </div>
                  </div>
                  <div>
                    <div className="row align-items-center justify-content-between mx-0">
                      <div>
                        {/* <h6 
                          onClick={this.onOpenAddCardModal}
                          style={
                            !this.state.payCardDropdown?.length
                              ? {
                                  color: '#1071eb',
                                  margin: '0 auto',
                                  display: 'block'
                                }
                              : {}
                          }
                          className=""
                        >
                          {this.state.payCardDropdown?.length
                            ? 'Card'
                            : 'Please add new card'}{' '}
                        </h6> */}
                        {this.state.updateCard && (
                          <CommonButtons
                            onClick={this.onOpenAddCardModal}
                            content="Edit card"
                            style={{
                              backgroundColor: '#0071eb',
                              color: 'white'
                            }}
                            loading={this.state.applyCouponLoader}
                          />
                        )}
                      </div>
                      <h6>
                        {this.state.payCardDropdown?.length === 1 ? (
                          <p className="summary-due-price show-card">
                            <div>
                              {this.state.payCardDropdown.map(item => {
                                return (
                                  <div className="">
                                    <span
                                      style={{ fontSize: '1rem' }}
                                    >{`*** ${item.text}`}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </p>
                        ) : null}
                      </h6>
                    </div>
                  </div>
                  <div className="summary-drop">
                    <CommonAddCardModal
                      open={this.state.addCardModalOpen}
                      onCloseAddCardModal={this.onCloseAddCardModal}
                      handleCreditCardInfo={this.handleCreditCardInfo}
                      creditCardDetailsErrors={
                        this.state.dataModal.creditCardDetailsErrors
                      }
                      errorMsg={this.state.errorMsg}
                      addCreditCardLoader={this.state.addCreditCardLoader}
                      onAddCreditCard={this.onAddCreditCard}
                      updateCard={this.state.updateCard}
                    />
                  </div>
                  <div className="holder-credit-card">
                    <CommonButtons
                      onClick={this.checkSubscription}
                      content={
                        this.state.activePlans
                          ? this.state.activePlans.price <
                            this.state.selectedPlan.activePrice
                            ? 'Upgrade Subscription'
                            : this.state.activePlans.price >
                              this.state.selectedPlan.activePrice
                            ? 'Downgrade Subscription'
                            : 'Update Subscription'
                          : 'Update Subscription'
                      }
                      background="blue"
                    />
                  </div>

                  <ConfirmUpgradePlanModal
                    open={this.state.confirmOnOpen}
                    onCloseUpgradePlanModal={this.onCloseUpgradePlanModal}
                    dataTable={dataTable || {}}
                    loading={this.state.addPaymentLoader}
                    addPayment={this.addPayment}
                  />
                </div>
              )}
              {cardSummary && (
                <div className="saved-cards">
                  <p className="saved-cards-title">Saved Cards</p>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Card Number</Table.HeaderCell>
                        <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.payCard.map(item => (
                        <Table.Row>
                          <Table.Cell>
                            <Checkbox
                              text=""
                              checkboxStyle="modal-checkbox"
                              name="checkbox"
                              value={this.state.creditCardDetails}
                              onChange={(e, data, checked) =>
                                this.onChangeCheckBox(e, data, checked, item)
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            ****
                            {item.card.last_four_digits}
                          </Table.Cell>
                          <Table.Cell>
                            {' '}
                            {item.card.expiry_month +
                              '/' +
                              item.card.expiry_year}{' '}
                          </Table.Cell>
                          <Table.Cell>Active</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div className="card-info">
                    {this.state.newCard ? (
                      <>
                        <p className="card-title">Update Card</p>
                        <CommonInput
                          onChange={this.handleCreditCardInfo}
                          name="cardName"
                          title="NAME ON CARD"
                          error={cardName}
                        />
                        <div className="holder-card-number">
                          <CommonInput
                            onChange={this.handleCreditCardInfo}
                            name="cardNumber"
                            title="CARD NUMBER"
                            error={cardNumber}
                          />
                          <div className="holder-images">
                            <Image src={visa} />
                            <Image src={americanExpress} />
                            <Image src={masterCard} />
                          </div>
                        </div>
                        <div className="holder-date">
                          <CommonInput
                            onChange={this.handleCreditCardInfo}
                            name="validMonth"
                            title="VALID THRU"
                            placeholder="Month"
                            error={validMonth}
                          />
                          <CommonInput
                            onChange={this.handleCreditCardInfo}
                            name="validYear"
                            placeholder="Year"
                            error={validYear}
                          />
                        </div>
                        <CommonInput
                          onChange={this.handleCreditCardInfo}
                          name="cvv"
                          title="CVV"
                          error={cvv}
                        />
                        <span className="secure-text">
                          <Image src={checked} />
                          100% secure checkout
                        </span>
                        <span className="commercial-text">
                          <Image src={checked} />
                          256-Bit Commercial grade Security
                        </span>
                        <CommonButtons
                          content="Update Card"
                          background="blue"
                          loading={this.state.addCreditCardLoader}
                          onClick={this.onAddCreditCard}
                        />
                      </>
                    ) : (
                      <CommonButtons
                        content="Add New Card"
                        background="blue"
                        onClick={() => this.setState({ newCard: true })}
                      />
                    )}
                  </div>
                  <div>
                    <div className="applyNowToggleContainer">
                      <Toggle
                        activeDefault={this.state.subscriptionData.apply_now}
                        dataStateToggle={this.applyNowToggle}
                        dataToggleActive={this.state.subscriptionData.apply_now}
                        handleToggleData={this.handleToggleData}
                      />
                      <p>Apply now</p>
                    </div>
                    <div className="changeSubscription">
                      <CommonButtons
                        content="Change subscription"
                        background="blue"
                        loading={this.state.addPaymentLoader}
                        onClick={this.addPayment}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Content>
        </Modal>
        <Modal
          className="model-review"
          size="mini"
          dimmer={false}
          open={this.state?.openHelp}
          onClose={() => this.setState({ openHelp: !this.state?.openHelp })}
          // trigger={
          //   <img
          //     src={open === false ? callLogPic : callLogActive}
          //     onClick={handleModalOpen}
          //     onMouseOver={() => handleModalOpen(true)}
          //     alt="img"
          //   />
          // }
        >
          <Modal.Content>
            <div className="hours-call-wrapper">
              <div className="hours-call-holder">
                {/* <a href="#" onClick={handleHelpUsLink}> */}
                <a href="http://support.limecall.com/" target="_blank">
                  <p className="hours-title">Get help from us</p>
                </a>
              </div>

              <div className="hours-call-holder">
                <Link to="/logout">
                  <p className="hours-title">Log Out</p>
                </Link>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  plansData: state.home.plansData,
  subScription: state.home?.subscription?.data
})

export default connect(mapStateToProps, { plansAction, getSubscription })(
  CommonSubscriptionModal
)
