import React, { useState } from 'react'
import eyeIcon from '../assets/images/eye-icon.png'
import { Button } from 'semantic-ui-react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CommonSubscriptionModal from './CommonSubscriptionModal'
import { connect } from 'react-redux'
import { getSubscription } from '../config/subscription'
const AnalyticsNoData = ({ subscription }) => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  return (
    <div className="no-analytics">
      <img src={eyeIcon} alt="eye" />
      <h3>Get insight</h3>

      <p>
        How much time did your team spend talking to customers last week? What
        is the busiest time of the week for you? Upgrade to business to unlock
        dashboard analytics.
      </p>
      <Button onClick={() => setShowSubscriptionModal(!showSubscriptionModal)}>
        <span>Compare Plans</span> <ArrowForwardIcon />
      </Button>

      <CommonSubscriptionModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(!showSubscriptionModal)}
        currentPlan={subscription?.plan_name}
        title="Choose Your Plan"
        dataTable={subscription?.subscriptionData}
      />
    </div>
  )
}
const mapStateToProps = state => ({
  subscription: state.home.subscription
})

export default connect(mapStateToProps, {
  getSubscription: getSubscription
})(AnalyticsNoData)
