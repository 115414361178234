import React, { useMemo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Table,
  Popup,
  Dropdown,
  Image,
  Tab,
  Icon,
  Modal
} from 'semantic-ui-react'
import MissedCall from '../../../assets/images/orangeCall.png'
import PickedCall from '../../../assets/images/greenCall.png'
import cutCall from '../../../assets/images/callRed.jpeg'
// import smsIcon from '../../../assets/images/sms.png'
import leadsIcon from '../../../assets/images/lead-icon.png'
import letterIcon from '../../../assets/images/letter-l.png'
// import telephone from '../../../assets/images/telephone.png'
import incomingIcon from '../../../assets/images/incoming-call.png'
import outboundIcon from '../../../assets/images/outbound-phone.png'
import scheduleIcon from '../../../assets/images/schedule.png'
// import deleteIcon from '../../../assets/images/delete-icon.png'
import moment from 'moment'
// import Select from 'react-dropdown-select'
import {
  onCallRequest,
  onChangeInterseted3,
  onLeadStageHandler,
  onLeadScoreHandler,
  onStatusChangeHandler3,
  onChangeOwner3,
  setLeadStage
} from '../../../config/leadAPI'
import SendMessageModal from '../../../common/SendMessageModal'
import DummyProfileImage from '../../../assets/images/dummy_profile.webp'
import emailicon from '../../../assets/images/mail_icons.png'
import smallcall from '../../../assets/images/small_call.png'
import smallEmail from '../../../assets/images/small_email.png'
import CommonCheckbox from '../../../common/CommonCheckbox'
import callicons from '../../../assets/images/call_icons.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DialerImage from '../../../assets/images/flaticons/dialer.png'

import CommonButtons from '../../../common/CommonButtons'

import AddTagPopup from '../leadRightPane/AddTagPopup'

import axios from 'axios'
import { CommonNotify } from '../../../common/CommonNotify'
import { getDialer } from '../../../actions/dialer'
import { getSubscription } from '../../../config/subscription'
import getWidget from '../../../config/getWidget'
import { Tooltip } from '@mui/material'

const leadStatusIcons = {
  initiated: 'initiated',
  canceled: 'canceled',
  suspended: 'suspended',
  no_agents_available: 'no_agents_available',
  not_assigned: 'not_assigned',
  busy: 'busy',
  completed: 'completed',
  failed: 'failed',
  in_progress: 'in-progress',
  no_answer: 'no-answer',
  widget_not_available: 'widget_not_available',
  customer_connected: 'customer_connected',
  agent_connected: 'agent_connected',
  ringing: 'ringing',
  scheduled: 'scheduled',
  rescheduled: 'rescheduled',
  failed_to_connect_agent: 'failed_to_connect_agent',
  failed_to_connect_customer: 'failed_to_connect_customer',
  customer_call_completed: 'customer_call_completed',
  agent_call_completed: 'agent_call_completed',
  connecting_agent: 'connecting_agent',
  connecting_customer: 'connecting_customer',
  agent_failure: 'attempting to reach not available agent',
  waiting_for_agent: 'waiting_for_agent'
}

const successIcon = [
  'completed',
  'agent_call_completed',
  'customer_call_completed'
]
const warningIcon = [
  'in-progress',
  'waiting_for_agent',
  'busy',
  'no-answer',
  'customer_connected',
  'scheduled',
  'rescheduled',
  'ringing',
  'agent_connected',
  'connecting_agent',
  'connecting_customer'
]

const statusOption = [
  {
    key: 'Awaiting Review',
    text: 'Pending',
    value: 1
  },
  { key: 'Reviewed', text: 'Assigned', value: 2 },
  {
    key: 'Disqualified',
    text: 'Closed',
    value: 3
  },
  { key: 'Qualified', text: 'Qualified', value: 4 }
]

const LeadTableContent2 = props => {
  const {
    index,
    data,
    onClickMarkCheckbox,
    leadType,
    dropDownData,
    leadOwner,
    leadScore,
    rightPane,
    leadStage,
    handleRightPaneOpen,
    setUpdatedTable,
    updatedPane,
    allTags,
    reloadTable,
    deleteLead,
    selectedRecordIds,
    tableFullData,
    fvLeadList,
    fetchFLead
  } = props
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [playingAudio, setPlayingAudio] = useState(0)
  const [playingAudioContext, setPlayingAudioContext] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedLead, setSelectedLead] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [stage, setStage] = useState([])
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [tagsUpdated, setTagsUpdated] = useState(false)
  const [leadData, setLeadData] = useState(null)

  useEffect(() => {
    if (!tagsUpdated) return
    if (!tags.length) return
    saveTags()
  }, [tags])

  useEffect(() => {
    if (!allTags.length) return

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }
    fetchTag(str)
  }, [allTags])

  const onStatusChange = (e, data, lead_id) => {
    if (lead_id[0] === '#') {
      lead_id = lead_id.substring(1)
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
    const postData = {
      lead_id: lead_id,
      status: data?.value
    }
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead status updated successfully.')
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update lead status')
      })
  }

  const onStatusChangeHandlerUpdate = (e, dat, data) => {
    onStatusChangeHandler3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update lead status')
      })
  }

  const onChangeIntersetedUpdate = (e, dat, data) => {
    onChangeInterseted3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update  lead interseted')
      })
  }

  const onChangeOwnerUpdate = (e, dat, data) => {
    onChangeOwner3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update  lead Owner')
      })
  }

  const stopAudio = id => {
    if (playingAudio != id) return
    const audio = playingAudioContext
    audio.pause()
    setPlayingAudioContext(null)
    setPlayingAudio(0)
  }

  const addTag = (tag, index) => {
    let avilable = false

    tags.forEach(tg => {
      if (tg.tag.toLowerCase() === tag.toLowerCase()) {
        avilable = true
      }
    })

    if (avilable) return
    let newTags = [...tags]
    newTags.push(allTags[index])
    setTags(newTags)
    setTagsUpdated(true)
  }

  const playAudio = (id, url) => {
    // console.log("playing recording "+url)
    if (playingAudio) return
    setPlayingAudio(id)

    const audio = new Audio(url)
    audio.play()
    setPlayingAudioContext(audio)
  }

  const fetchTag = id => {
    if (data.tags) {
      if (data.tags.length) {
        fetchTags(data.tags)
        return
      }
    }

    fetchTags([])
  }

  const fetchTags = tags => {
    let tgs = [...tags]
    const tg = tgs

    tg.forEach((item, index) => {
      if (!getTag(item)) {
        tgs.splice(index, 1)
      }
    })

    const newTags = tgs.map(item => {
      return getTag(item)
    })

    setTags(newTags)
  }

  const saveTags = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    const pTags = tags.map(tag => {
      return tag.tag
    })

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }

    const postData = {
      tags: pTags,
      lead_id: str
    }

    axios
      .post(URL, postData)
      .then(res => {})
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const checkIcon = status => {
    if (successIcon.includes(status)) {
      return PickedCall
    }
    if (warningIcon.includes(status)) {
      return MissedCall
    }
    return cutCall
  }

  const getTag = tag => {
    let selectedTag = -1
    allTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    if (selectedTag == -1) return false
    return allTags[selectedTag]
  }

  const removeTag = tag => {
    let selectedTag = -1
    const selTags = [...tags]
    selTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    selTags.splice(selectedTag, 1)
    setTags(selTags)
    setTagsUpdated(true)
  }

  const handelClick = () => {
    history.push(`/leads/${data.id.substring(1)}`)
  }

  const isModalClose = () => {
    setOpen(false)
  }

  const isModalOpen = () => {
    setOpen(true)
  }

  const openDialer = () => {
    return props?.getDialer(!props.dialerStatus)
  }

  const data_popup = () => {
    return (
      <>
        <div className="dispaly_profile1" style={{ width: '70% !important' }}>
          <div className="profile_boxes1">
            <div className="profile_left1">
              <div className="profile_images1">
                <img src={DummyProfileImage} />
              </div>
              <div className="profile_detail1">
                <p style={{ marginTop: 7 }}>
                  {data.customerName !== null && data.customerName !== 'null'
                    ? data.customerName
                    : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="dispaly_profile_detail1">
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                ID : <span>{data.id} </span>
              </pre>
            </div>

            {data.phone_number &&
            data.phone_number !== '' &&
            data.phone_number !== null ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <img src={smallcall} style={{ height: '130px' }} />
                </div>
                <pre>
                  Contact : <span>{data.phone_number}</span>
                </pre>
              </div>
            ) : (
              ' '
            )}
            {data.email && data.email !== 'null' ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <img src={smallEmail} style={{ height: '130px' }} />
                </div>
                <pre>
                  Email : <span>{data.email} </span>
                </pre>
              </div>
            ) : (
              ' '
            )}

            {leadData && (
              <>
                {leadData.company_name && leadData.company_name !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="trademark" />
                    </div>
                    <pre>
                      Company : <span>{leadData.company_name} </span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}

                {leadData.country && leadData.country !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="flag" />
                    </div>
                    <pre>
                      Country : <span>{leadData.country}</span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}

                {leadData.ip_address && leadData.ip_address !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="world" />
                    </div>
                    <pre>
                      IP : <span>{leadData.ip_address}</span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}
              </>
            )}

            {data.type === 'LIVE_CALL' ? (
              <div className="display_button1">
                <div className="row">
                  <a
                    style={{
                      height: '30px',
                      padding: '3px 10px',
                      width: '100px'
                    }}
                    className="call_set1 col"
                    onClick={() => onCallRequest(data.id)}
                  >
                    <img src={callicons} style={{ maxHeight: '15px' }} />
                  </a>
                  <a
                    style={{
                      height: '30px',
                      padding: '3px 10px',
                      width: '100px'
                    }}
                    className="mail_set1 col"
                    onClick={() => setOpen(true)}
                  >
                    <img src={emailicon} style={{ maxHeight: '15px' }} />
                  </a>
                </div>
              </div>
            ) : (
              ' '
            )}
          </div>
        </div>
      </>
    )
  }

  const data_popup2 = () => {
    return (
      <>
        <div className="dispaly_profile1">
          <div className="profile_boxes1">
            <div className="profile_left1">
              <div className="profile_images1">
                {/* <img src={DummyProfileImage} /> */}
              </div>
              <div className="profile_detail1">
                <p style={{ marginTop: 7 }}>Call Information</p>
              </div>
            </div>
          </div>
          <div className="dispaly_profile_detail1">
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Direction : <span>{data.direction ? data.direction : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Type :{' '}
                <span>{data.type ? data.type.replace('_', ' ') : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Team : <span>{data.team_name ? data.team_name : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Agent : <span>{data.agent ? data.agent : ''} </span>
              </pre>
            </div>
            {data.final_status !== 'completed' ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <span style={{ color: '#fff' }}>#</span>
                </div>
                <pre>
                  Failure Reason :{' '}
                  <span>{data.failure_reason ? data.failure_reason : ''} </span>
                </pre>
              </div>
            ) : (
              <div></div>
            )}

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Source : <span>{data?.source ? data?.source : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Message :{' '}
                <span>{data?.widget_message ? data?.widget_message : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Team name :{' '}
                <span>{data?.team_name ? data?.team_name : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Score : <span>{data?.score ? data?.score : ''} </span>
              </pre>
            </div>

            {/* <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
              Technical Code : <span>{data?.tech_code ? data?.tech_code : ''} </span>
              </pre>
            </div>        */}
          </div>
        </div>
      </>
    )
  }

  const options = [
    { key: 1, text: 'Good Fit', value: 1 },
    { key: 2, text: 'Pending', value: 2 },
    { key: 3, text: 'Bad Fit', value: 3 }
  ]

  const statusOptions = [
    { key: 1, text: 'Awaiting Review', value: 1 },
    { key: 2, text: 'In progress', value: 2 },
    { key: 3, text: 'Qualified', value: 4 },
    { key: 4, text: 'Closed', value: 3 }
  ]

  const leadScoreOption = [
    { key: 1, text: 'Bad Fit', value: 'Bad fit' },
    { key: 2, text: 'Qualified', value: 'Qualified' }
  ]
  const ownerDropdown = [
    {
      key: 1,
      text: 'Assigned',
      value: 'Assigned'
    },
    {
      key: 2,
      text: 'Qualified',
      value: 'Qualified'
    },
    {
      key: 3,
      text: 'Negotiation',
      value: 'Negotiation'
    },
    {
      key: 4,
      text: 'Won',
      value: 'Won'
    },
    {
      key: 5,
      text: 'Lost',
      value: 'Lost'
    }
  ]
  const onStageSelected = data => {
    setLeadStage(466, stage, setLoading)
  }

  const onStageValueChanged = (a, b, c) => {
    const s = b.value

    setUpdatedTable()
    onLeadStageHandler(s, c)
  }
  const leadId = data?.id?.replace('#', '')

  const getContact = (contact, id) => {
    const invalidResponse = ['null', 'undefined', '', false]
    let result = invalidResponse.find(item => {
      if (item === contact) return item
    })
    if (result) {
      return 'Contact ' + id
    }
    return contact
  }

  const deleteModalView = async (bool, lead = 0) => {
    if (lead) {
      setSelectedLead(lead)
    }
    setDeleteModal(bool)
  }

  const onDeleteLeadHandler = async () => {
    setIsLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = selectedRecordIds?.length ? selectedRecordIds : [selectedLead]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        CommonNotify('Lead Deleted Successfully', 'success')
        setSelectedLead(0)
        setIsLoading(false)
        reloadTable()
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify('Not able to Delete Lead')
      })
  }

  const toggleFLead = async (id, fav) => {
    const url = `${
      process.env.REACT_APP_BASE_APP_URL
    }/api/v1/leads/${id?.replace('#', '')}/flags`
    if (fav) {
      axios
        .post(url, {
          flag: 1
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            // fetchFLead()
            reloadTable()
          }
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      axios
        .delete(url, {
          data: {
            flag: 1
          }
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            // fetchFLead()
            reloadTable()
          }
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    }
  }

  const isFLead = (id, data) => {
    if (data?.is_fav?.toString() === '1') {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Table.Row className="table-content-row">
        <Table.Cell data-key={index}>
          <div style={{ display: 'flex' }}>
            <CommonCheckbox
              onChange={() => onClickMarkCheckbox(index)}
              name="isChecked"
              checked={data.isChecked ? true : false}
            />
            <i
              onClick={e => {
                toggleFLead(
                  data?.id?.replace('#', ''),
                  +data?.is_fav === 1 ? false : true
                )
              }}
              style={
                isFLead(data?.id?.toString()?.replace('#', ''), data)
                  ? {
                      fontSize: '20px',
                      margin: '10px',
                      color: '#0071eb',
                      marginTop: '-2px',
                      paddingLeft: '21px'
                    }
                  : {
                      fontSize: '20px',
                      margin: '10px',
                      color: 'rgb(203, 211, 227)',
                      marginTop: '-2px',
                      paddingLeft: '21px'
                    }
              }
              className="icon star"
            ></i>
          </div>
        </Table.Cell>
        <Table.Cell style={{}} className="image-size">
          {/* <div onClick={handelClick} title={data?.type || ''}> */}
          <div
            onClick={() => handleRightPaneOpen(data.id.substring(1))}
            title={data?.type || ''}
          >
            {data.id}
            <img
              src={
                data?.type === 'LIVE_CALL' || data?.type === 'DIGITAL_CALL'
                  ? incomingIcon
                  : data?.type === 'LEAVE_MESSAGE'
                  ? leadsIcon
                  : data?.type === 'SCHEDULE_CALL'
                  ? scheduleIcon
                  : data?.type === 'OUTBOUND_CALL'
                  ? outboundIcon
                  : letterIcon
              }
              alt="icon"
            />
          </div>
          <div className="preview" style={{ margin: '12px auto' }}>
            <CommonButtons
              style={{ display: 'block', fontSize: '8px', padding: '7px 8px' }}
              content="Preview"
              background="blue"
              btnClass="btn-verify"
              onClick={() => handleRightPaneOpen(data.id.substring(1))}
            />
          </div>
        </Table.Cell>

        <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
          {moment
            .utc(
              leadType === 'SCHEDULE_CALL'
                ? data?.request_call_time || data?.time
                : data?.time
            )
            .local()
            .startOf('seconds')
            .fromNow()}
        </Table.Cell>
        <Table.Cell>
          {!data.isHoverDisplay && data.contact ? (
            <Popup
              className="profile_popup"
              content={data_popup}
              size="mini"
              position="bottom left"
              flowing
              hoverable
              trigger={<p>{getContact(data.contact, data.id)}</p>}
            />
          ) : (
            <p>{data.contact ? data.contact : 'Contact ' + data.id}</p>
          )}
        </Table.Cell>

        <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
          <div className={data?.source === 'Webpage' ? 'webpage' : 'facebook'}>
            <Tooltip title={tableFullData?.leads?.data[index]?.source || ''}>
              <span>
                {data.source === null ? 'Unknown' : data.source || ''}
              </span>
            </Tooltip>
          </div>
        </Table.Cell>

        <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
          <div>{data.agent ? data.agent : ''}</div>
        </Table.Cell>

        <Table.Cell className="Lead Owne">
          <Popup
            trigger={
              <Image
                src={checkIcon(data.call_status)}
                className={rightPane ? 'rightPopUp' : ''}
                style={{ width: 25, height: 25 }}
              />
            }
            position="top center"
            size="mini"
            flowing
            hoverable
            content={data_popup2}
          >
            {/* {data.call_status
                  ? data.call_status.replace(/_/g, ' ')
                  : 'Call status not assigned'} */}
          </Popup>
        </Table.Cell>

        <Table.Cell className="Lead Owner">
          {data && <>{data.duration ? data.duration + ' secs' : '0 sec'}</>}
          {data && (
            <>
              {data.recording_url !== null && (
                <>
                  {playingAudio != data.id && (
                    <Icon
                      style={{ marginLeft: '10px', fontSize: '1.8em' }}
                      name="video play"
                      onClick={e => playAudio(data.id, data.recording_url)}
                    />
                  )}

                  {playingAudio == data.id && (
                    <Icon
                      style={{ marginLeft: '10px', fontSize: '1.8em' }}
                      name="pause circle"
                      onClick={e => stopAudio(data.id)}
                    />
                  )}
                </>
              )}
            </>
          )}
          {data?.cost ? <p>{`${data.cost}$`}</p> : null}
        </Table.Cell>

        <Table.Cell className="Lead Owner">
          {data.failure_reason ? data.failure_reason : ''}
        </Table.Cell>

        {/* <Table.Cell style={{ textAlign: 'right' }}>
          <Icon
            style={{
              marginLeft: '10px',
              fontSize: '1.8em',
              maxWidth: '2px',
              overflow: 'hidden'
            }}
            name="sidebar"
          />
        </Table.Cell> */}

        {/* <Table.Cell className="Lead Owner">
          <Dropdown
            options={statusOption}
            placeholder="Lead Status"
            defaultValue={parseInt(+data?.status)}
            onChange={(e, dat) => onStatusChange(e, dat, data.id)}
          />
        </Table.Cell> */}
        <Table.Cell>
          <div style={{ display: 'inline-block' }}>
            <DeleteForeverIcon
              fontSize="large"
              color="action"
              onClick={() => deleteModalView(true, data?.id?.substring(1))}
            />
            {data?.phone_number && (
              <>
                <a
                  onClick={() => openDialer()}
                  data-tip="Open dialer"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    verticalAlign: 'top'
                  }}
                >
                  <img
                    style={{ width: '30px', marginTop: '-5px' }}
                    src={DialerImage}
                  />
                </a>
                <a
                  onClick={() => setOpen(true)}
                  data-tip="Send SMS & MMS"
                  href="javascript:void(0)"
                >
                  <i
                    className="fa fa-envelope"
                    aria-setsize="20"
                    style={{ color: '#2185d0', fontSize: 21, marginRight: 10 }}
                    aria-hidden="true"
                  ></i>
                </a>
              </>
            )}

            {/* <Popup
              className="profile_popup"
              content={<AddTagPopup tags={allTags} addTag={addTag} />}
              size="large"
              position="bottom center"
              flowing
              on="click"
              trigger={
                <div style={{ marginTop: '15px' }}>
                  {!tags.length && (
                    <a href="javascript:void(0)"> + Add New Tag</a>
                  )}{' '}
                </div>
              }
            /> */}
          </div>
          {open && (
            <SendMessageModal
              closeAfterSend={isModalClose}
              isModalClose={isModalClose}
              isModalOpen={isModalOpen}
              open={open}
              dropDownData={dropDownData}
              leadData={data}
            />
          )}
        </Table.Cell>
      </Table.Row>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete the lead id{' '}
              {selectedRecordIds?.length
                ? selectedRecordIds.join(', ')
                : selectedLead}
              ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  dialerStatus: state.dialer.currentStatus
})

export default connect(mapStateToProps, {
  getWidget,
  getSubscription,
  getDialer
})(LeadTableContent2)
